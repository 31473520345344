import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Navbar from './components/Navbar';
import Homepage from './components/Homepage';
import Projects from './components/Projects';
// import Project from "./components/ProjectPage";
import About from './components/About';
import Contact from './components/Contact';
import Login from './components/Login';
import Upload from './components/Upload';
import ProfilePage from './components/Profile/Profile';
import EditProfilePage from './components/Profile/EditProfile';
import ProjectPage from './components/ProjectPage';
// import Popup from 'reactjs-popup';
import Footer from './components/Footer';
import Register from './components/Register';
import CookiePolicy from './components/Utils/CookiePolicy';
import { useLocation } from 'react-router-dom';
import { useTransition, animated, useSpringRef } from 'react-spring';
import Portfolio from './components/portfolio/Portfolio';

function App() {
  const location = useLocation();
  const transRef = useSpringRef();
  const transitions = useTransition(location, {
    ref: transRef,
    keys: null,
    from: { opacity: 0, scale: 0, position: 'absolute' },
    enter: { opacity: 1, scale: 1, transform: 'translate3d(0%,0,0)', position: 'relative' },
    leave: { opacity: 0, scale: 0, transform: 'translate3d(-100%,0,0)', position: 'absolute' },
    delay: 200,
    config: { mass: 1, tension: 100, friction: 20 }
    // exitBeforeEnter: true
  });
  const [cookieOpen, setCookieOpen] = useState(false);

  useEffect(() => {
    const cookiePolicy = localStorage.getItem('sutd-project-showcase-cookie-policy');

    if (!cookiePolicy) {
      setCookieOpen(true);
    }
  }, []);
  useEffect(() => {
    transRef.start();
  }, [location]);
  return (
    <div className="App min-w-screen min-h-screen bgOffWhite">
      {transitions((props, item) => (
        <animated.div style={props} className="bgOffWhite">
          <Navbar />
          <Routes location={item}>
            <Route path="/" element={<Homepage />} />
            {/* <Route path="/projects" element={<Projects />} /> */}
            <Route exact path="/projects/:projectID" element={<ProjectPage />}></Route>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} /> */}
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/upload" element={<Upload />} />
            <Route exact path="/profile/:userId" element={<ProfilePage />} />
            <Route exact path="/edit/profile" element={<EditProfilePage />} />
          </Routes>
          <Footer />
          <CookiePolicy open={cookieOpen} setOpen={setCookieOpen} />
        </animated.div>
      ))}
    </div>
  );

  // return (
  //   <div className="App">
  //     {/* <h1 className="text-3xl font-bold underline"> Uncomment to test if Tailwind CSS is working</h1> */}
  //     <Navbar />

  //     <Routes>
  //       <Route path="/" element={<Homepage />} />
  //       <Route path="/projects" element={<Projects />} />
  //       <Route exact path="/projects/:projectID" element={<ProjectPage />}></Route>
  //       <Route path="/about" element={<About />} />
  //       <Route path="/contact" element={<Contact />} />
  //       <Route path="/login" element={<Login />} />
  //       <Route path="/register" element={<Register />} />
  //       <Route path="/upload" element={<Upload />} />
  //       <Route exact path="/profile/:userId" element={<ProfilePage />} />
  //       <Route exact path="/edit/profile" element={<EditProfilePage />} />
  //     </Routes>
  //     <Footer />
  //     <CookiePolicy open={cookieOpen} setOpen={setCookieOpen} />
  //   </div>
  // );
}

export default App;
