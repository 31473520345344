import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useAppContext from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
// import { faThumbsUp as DarkThumb } from "@fortawesome/free-solid-svg-icons";
// import { faThumbsUp as LightThumb } from "@fortawesome/free-regular-svg-icons";
import paperAirplane from "../images/paper_airplane.png";
import headerLines from "../images/header_lines.png";
import polygonShape from "../images/polygon_shape.png";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "../axiosConfig";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce } from "lodash";
// import TextField from "@mui/material/TextField";
import Card from "./Card";
// import { bgcolor, fontFamily } from "@mui/system";
import "../index.css";

// list of names
// axios.defaults.baseURL =
//   "http://localhost:8000";

export default function Homepage() {
  const axiosPrivate = useAxiosPrivate();
  const { token } = useAppContext();
  let navigate = useNavigate();
  const pageSize = 6;
  const [listOfProjects, setListOfProjects] = useState([]);
  const [pageOffSet, setPageOffSet] = useState({ current: 0, max: 0 });
  const [tags, setTags] = useState([]);
  const [courses, setCourses] = useState([]);
  const [totalDocCount, setTotalDocCount] = useState(0);

  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [textQuery, setTextQuery] = useState("");

  // useEffect(() => {
  //   getAllPosts();
  //   getTags();
  //   getCourses();
  //   setFilteredProjects(
  //     [...listOfProjects].sort((a, b) => (a.upvote < b.upvote ? 1 : -1))
  //   );
  // }, [state]);
  // useEffect(() => {
  //   getAllPosts();
  // }, [token])
  useEffect(() => {
    getTags();
    getCourses();
  }, []);

  useEffect(() => {
    // let projects = listOfProjects.slice()
    //
    // if (selectedTerm !== null) {
    //   projects = projects.filter(project => project.term === selectedTerm)
    // }
    //
    // if (selectedTag.length > 0) {
    //   console.log(selectedTag)
    //   projects = projects.filter(project => {
    //     return selectedTag.every(item => {
    //       var found = false;
    //       for (var i = 0; i < project.tag.length && !found; i++) {
    //         if (project.tag[i].name === item.name) {
    //           found = true;
    //         }
    //       }
    //       return found
    //     })
    //     // return project.tag.some(projectTag => selectedTag.includes(projectTag))
    //     // return project.tag.every(tag => selectedTag.includes(tag._id))
    //   })
    // }
    //
    // if (selectedCourse !== null) {
    //   projects = projects.filter(project => {
    //
    //     return project.courseNo._id === selectedCourse._id
    //   }
    //   )
    // }
    //

    searchQuery(0);
  }, [selectedTerm, textQuery, selectedTag, selectedCourse, token]);

  const handlePageChange = async (toChange) => {
    setPageOffSet({
      current: pageOffSet.current + toChange,
      max: pageOffSet.max,
    });
    await searchQuery(pageOffSet.current + toChange);
  };

  const searchQuery = async (offset) => {
    var queryArray = [];
    if (selectedTag.length > 0) {
      var tagString = "tagQuery=";
      for (let i = 0; i < selectedTag.length; i++) {
        console.log(i, selectedTag.length);
        if (i !== 0) {
          tagString += `,${selectedTag[i].name}`;
        } else {
          tagString += `${selectedTag[i].name}`;
        }
      }
      queryArray.push(tagString);
    }
    if (selectedCourse) {
      queryArray.push(`courseQuery=${selectedCourse._id}`);
    }
    if (selectedTerm) {
      queryArray.push(`termQuery=${selectedTerm}`);
    }
    if (textQuery) {
      queryArray.push(`textQuery=${textQuery}`);
    }
    const queryString = queryArray.join("&");

    try {
      //use post request in the future to prevent unwanted string clash of & and ?
      var url = `/api/posts?offset=${offset * pageSize}&size=${pageSize}`;
      if (queryString) {
        url = `${url}&${queryString}`;
      }
      const response = await axiosPrivate.get(url);
      if (response.data === "Theres no projects found") {
        setListOfProjects([]);
        setPageOffSet({ current: 0, max: 0 });
      } else {
        setListOfProjects(response.data.projects);
        setTotalDocCount(response.data.totalDocumentCount);
        const maxPageNum = Math.floor(
          response.data.totalDocumentCount / pageSize
        );
        setPageOffSet((previouState) => ({
          ...previouState,
          max:
            response.data.totalDocumentCount % pageSize === 0
              ? maxPageNum - 1
              : maxPageNum,
        }));
        // setPageOffSet({
        //   current: 0,
        //   max:
        //     response.data.totalDocumentCount % pageSize === 0
        //       ? maxPageNum - 1
        //       : maxPageNum,
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   if (pageSize * pageOffSet.current < listOfProjects.length) {
  //     setProjectToDisplay(
  //       listOfProjects.slice(
  //         pageSize * pageOffSet.current,
  //         pageSize + pageSize * pageOffSet.current
  //       )
  //     );
  //   } else {
  //     setProjectToDisplay(listOfProjects.slice(pageSize * pageOffSet.current));
  //   }
  // }, [pageOffSet, listOfProjects]);

  const handleTagChange = (event, value) => {
    setSelectedTag(value);
  };
  const handleCourse = (event, value) => {
    console.log(value);
    setSelectedCourse(value);
  };
  const handleTerm = (event, value) => {
    //extract last chacter of value to intger
    if (value === null) {
      setSelectedTerm(null);
    } else {
      try {
        const termNo = parseInt(value.slice(-1));

        setSelectedTerm(termNo);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const debouncedSearch = useRef(
    debounce(async (text) => {
      var cleanedText = text
        .replace("&", "")
        .replace("?", " ")
        .replace("=", " ");
      setTextQuery(cleanedText);
    }, 300)
  ).current;
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  const handleTextSearch = (event) => {
    debouncedSearch(event.target.value);
  };

  // function getAllPosts() {
  //     axiosPrivate.get("/api/posts", {})
  //     .then(function (response) {
  //
  //       setListOfProjects(response.data);
  //       // setPageOffSet({ current: 0, max: Math.floor(response.data.length / 18) })
  //     })
  //     .catch(function (error) {
  //       console.log("error rcvd", error);
  //     });
  // }

  function getTags() {
    axios
      .get("/api/posts/tags")
      .then((res) => setTags(res.data))
      .catch((err) => console.log(err));
  }

  function getCourses() {
    axios
      .get("/api/posts/courses")
      .then((res) => setCourses(res.data))
      .catch((err) => console.log(err));
  }

  //TODO:need redo
  const navigateToRandomProject = () => {
    const randomIndex = Math.floor(Math.random() * listOfProjects.length);
    navigate(`/projects/${listOfProjects[randomIndex]._id}`);
  };

  return (
    // <h1>Home Page</h1>
    <div className="bg-bgOffWhite min-h-screen ">
      {/* {pageStatus.open ?
        <ProjectPage pageStatus={pageStatus} setPageStatus={setPageStatus} /> : */}

      <>
        <div className="grid relative overflow-hidden border-t-4 border-b-4 border-black max-w-7xl m-auto">
          {/* <img src={bannerImage} className="sm:w-full md:w-80 h-auto mx-auto" /> */}
          <div className="text-center w-full">
            <img
              src={headerLines}
              alt="headerLines"
              className="absolute left-0 top-0 h-full"
            />
            <img
              alt="paperAirplane"
              className="absolute right-0 top-0 sm:h-1/3 md:h-1/2"
              src={paperAirplane}
            />
            <img
              style={{ right: "-48px", bottom: "-45px" }}
              className="absolute h-1/2"
              alt="polygonShape"
              src={polygonShape}
            />

            <h1 className="mx-auto text-6xl sm:w-80 lg:w-full font-bold lg:mt-10 z-20">
              SUTDFOLIO
            </h1>
            <p className="lg:mt-10 text-xl">
              A consolidation of projects and experiences
              <br /> by SUTD students.
            </p>
          </div>
          <button
            className="bg-accentColor text-white h-8 text-xl mt-4 mb-10 px-2 block mx-auto right-0 left-0 "
            onClick={navigateToRandomProject}
          >
            Show me a post!
          </button>
        </div>
        <div className=" max-w-7xl mt-5 m-auto">
          <div className="grid md:grid-cols-3 mb-3 gap-x-4">
            <Autocomplete
              disablePortal
              style={{
                backgroundColor: "white",
              }}
              options={[1, 2, 3, 4, 5, 6, 7, 8].map(
                (option) => "Term " + option.toString()
              )}
              onChange={handleTerm}
              renderInput={(params) => <TextField {...params} label="Term" />}
            />

            <Autocomplete
              fullWidth
              style={{
                backgroundColor: "white",
              }}
              autoHighlight
              id="course-select"
              options={courses}
              // isOptionEqualToValue={(option, value) => option._id === value._id}
              value={selectedCourse ? selectedCourse : null}
              getOptionLabel={(option) =>
                `${option.courseName} (${option.courseNo})`
              }
              onChange={handleCourse}
              renderInput={(params) => <TextField {...params} label="Course" />}
            />
            <Autocomplete
              multiple
              style={{
                backgroundColor: "white",
              }}
              id="multiple-limit-tags"
              options={tags}
              // defaultValue={formValue.tag}
              //TODO FIX THE DEFAULT VALUE
              // formvalue.tag set to project.tag it displays correctly but during submit will face project cos tag object is submitted instead of tag id
              //if set the default value to project.tag doesnt work cos initally project is empty i think
              value={selectedTag}
              getOptionLabel={(option) => option.name}
              // defaultValue={ }
              renderInput={(params) => (
                <TextField {...params} placeholder="Tags" />
              )}
              onChange={handleTagChange}
            />
          </div>
          {/* search input and button */}
          {/* <div className="grid grid-flow-row-dense grid-cols-12"> */}
          {/*    <div className="sm:col-span-12 md:col-span-10 "> */}
          {/*      <Autocomplete */}
          {/*        style={{ */}
          {/*          backgroundColor: "white", */}
          {/*        }} */}
          {/*        options={listOfProjects} */}
          {/*        className="w-full h-10" */}
          {/*        getOptionLabel={(option) => option.title} */}
          {/*        popupIcon={false} */}
          {/*        inputValue={inputValue} */}
          {/*        onInputChange={inputChangeHandler} */}
          {/*        renderInput={(params) => { */}
          {/*          const { InputLabelProps, InputProps, ...rest } = params; */}
          {/*          return ( */}
          {/*            <InputBase */}
          {/*              style={{ */}
          {/*                paddingLeft: "16px", */}
          {/*                height: "100%", */}
          {/*                textAlign: "center", */}
          {/*                fontFamily: "Space Mono, monospace", */}
          {/*              }} */}
          {/*              // fullWidth="True" */}
          {/*              placeholder="Search by project, course name, etc" */}
          {/*              {...params.InputProps} */}
          {/*              {...rest} /> */}
          {/*          ); */}
          {/*        }} /> */}
          {/*    </div> */}
          {/*    <div className="grid sm:col-span-12 md:col-span-2 lg:col-span-1"> */}
          {/*      <button */}
          {/*        className="bg-accentColor text-white h-10 mb-4 text-xl" */}
          {/*        type="submit" */}
          {/*      > */}
          {/*        {inputValue ? ( */}
          {/*          <a href={`/projects/${inputValue}`}>Search</a> */}
          {/*        ) : ( */}
          {/*          <a href="#">Search</a> */}
          {/*        )} */}
          {/*      </button> */}
          {/*    </div> */}
          {/*  </div>  */}
          <div>
            <TextField
              id="outlined-search"
              label="Search by name or description"
              type="search"
              fullWidth
              onChange={handleTextSearch}
            />
          </div>

          {/* Sort button */}
          {/* <div className="relative h-10 w-full">
              <div className="pt-0">
                <div className="group relative">
                  <div className="h-10 w-50 flex justify-between absolute right-0 border-2 border-black bg-bgOffWhite">
                    <a className="grid pt-1 px-3 text-black w-auto text-center">
                      Sort By
                    </a>
                    <button className="bg-black text-white w-10 h-full">
                      <FontAwesomeIcon className="rotate-90" icon={faExchangeAlt} />
                    </button>
                  </div>
                  <nav
                    tabIndex="0"
                    className=" bg-white invisible border-none w-44 absolute right-0 top-full transition-all opacity-0 group-focus-within:visible group-focus-within:opacity-100 group-focus-within:translate-y-1 z-50 font-normal"
                  >
                    <ul className="py-1">
                      <li key='popularity'>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-filterHover"
                          onClick={() => sortProjects("Popularity")}
                        >
                          Popularity
                        </a>
                      </li>
                      <li key='most-likes'>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-filterHover"
                          onClick={() => sortProjects("Likes")}
                        >
                          Most Likes
                        </a>
                      </li>
                      <li key='newest-first'>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-filterHover"
                          onClick={() => sortProjects("Newest")}
                        >
                          Newest First
                        </a>
                      </li>
                      <li key='oldest-first'>
                        <a
                          href="#"
                          className="block px-4 py-2 hover:bg-filterHover"
                          onClick={() => sortProjects("Oldest")}
                        >
                          Oldest First
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div> */}

          {/* main content */}
          {/* React Spring */}

          {listOfProjects.length > 0 ? (
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 max-w-7xl mx-auto ">
              {listOfProjects.map((data, i) => {
                return (
                  <Card
                    key={i}
                    data={data}
                    setListOfProjects={setListOfProjects}
                  />
                );
              })}
            </div>
          ) : (
            <div className="w-full">
              <h1 className="text-center h-40 pt-8 text-lg w-full">
                Sorry, there are no results for this filter.
              </h1>
            </div>
          )}
          {/* pages buttons */}
          <div className="relative h-10 w-full mt-4">
            <div className="pt-0">
              <div className="group relative">
                <div className="h-10 w-50  flex justify-between absolute right-0 border-2 border-black bg-bgOffWhite">
                  {pageOffSet.current !== 0 && (
                    <button
                      className="bg-black text-white w-10 h-full"
                      onClick={() => {
                        handlePageChange(-1);
                      }}
                    >
                      <FontAwesomeIcon className="" icon={faChevronLeft} />
                    </button>
                  )}
                  <a className="grid pt-1 text-black w-auto text-center px-3">
                    {pageOffSet.current + 1} of {pageOffSet.max + 1}
                  </a>
                  {pageOffSet.current !== pageOffSet.max && (
                    <button
                      className="bg-black text-white w-10 h-full"
                      onClick={() => {
                        handlePageChange(1);
                      }}
                    >
                      <FontAwesomeIcon className="" icon={faChevronRight} />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="relative h-10 w-full pb-4 mt-2">
            <div className="pt-0">
              <div className="group relative">
                <div className="h-auto w-50  flex justify-between absolute right-0 bg-bgOffWhite">
                  <p className="grid pl-1 text-black w-auto text-center">
                    Showing {listOfProjects.length} of {totalDocCount} results
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* } */}
    </div>
  );
}
