// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it
var BASE_URL
if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://sutd-root-backend-w5e7n.ondigitalocean.app';
} else {
  BASE_URL = "http://localhost:8000"
}
export default axios.create({
  baseURL : BASE_URL
})
export const axiosPrivate = axios.create({
  baseURL :BASE_URL,
  withCredentials:true
})
