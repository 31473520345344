import { useParams, useNavigate, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Rating from './Rating';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAppContext from '../hooks/useAuth';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TelegramIcon from '@mui/icons-material/Telegram';
import parse from 'html-react-parser';
import emptyAvatar from '../images/icons/empty_avatar.png';
import DeletePostDialog from './Utils/DeletePostDialog';
import { Helmet } from 'react-helmet';
import { useTransition, animated } from '@react-spring/web';

export default function ProjectPage({ pageStatus, setPageStatus }) {
  const axiosPrivate = useAxiosPrivate();
  const { state } = useAppContext();
  const navigate = useNavigate();
  let params = useParams();
  const [projectData, setProjectData] = useState();

  const placeHoldderImage = 'http://i.stack.imgur.com/yZlqh.png';

  const [projectId, setProjectId] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState('');

  useEffect(() => {
    const getProjectData = async () => {
      try {
        let config = {
          method: 'get',
          url: `/api/posts/${params.projectID}`
        };

        const response = await axiosPrivate(config);
        setProjectData(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    console.log(params.projectID);
    if (params.projectID) {
      getProjectData();

      setProjectId(params.projectID);
    } else if (pageStatus.data !== null) {
      setProjectData(pageStatus.data);
      setProjectId(pageStatus.data._id);
    } else {
      setPageStatus({ open: false, data: null });
      navigate('/');
    }
  }, [state]);
  const getId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  };

  const transition = useTransition(projectData, {
    // trail: 400 / data.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 }
  });

  function renderRating() {
    return <Rating likedBool={projectData.liked} projectID={projectId} />;
  }
  const EditPost = (id) => {
    navigate(`/upload?project=${id}`);
  };

  const selectPost = (id) => {
    setDeleteDialogOpen(true);
    setSelectedPost(id);
  };

  const deletePost = async () => {
    try {
      await axiosPrivate({
        method: 'delete',
        url: `/api/posts/${selectedPost}`
      });
      setDeleteDialogOpen(false);
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true });
      }
    } catch (error) {
      if (error.response.status === 204) {
        window.location.reload(false);
      }
    }
  };

  return transition((_, data) => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:description" content={data.desc} />
        <meta
          property="og:image"
          content="https://sutdfolio.sgp1.cdn.digitaloceanspaces.com/projectImage/7ccb90fephoto_2022-05-19_22-05-59.jpg"
        />
        <meta property="og:site_name" content={data.title} />
        <meta property="og:title" content={data.title} />
        <meta property="og:type" content="website" />

        <title>{data.title}</title>
      </Helmet>
      <div className={`w-full bg-bgOffWhite min-h-screen flex justify-center `}>
        <div className="grid sm:grid-cols-1 text-center max-w-4xl">
          <div className="bg-bgOffWhite">
            {/* Back Button  */}
            <Link to="/" className="container flex justify-between items-center py-4 ml-4">
              <button
                className="bg-black  hover:bg-gray-400 textBlack text-lg inline-flex items-center border border-black"
                onClick={() => {
                  setPageStatus({ open: false, data: null });
                }}>
                <div className="grid grid-cols-2 gap-0">
                  <div className="bg-black flex items-center hover:bg-gray-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      strokeLinecap="round">
                      <path d="M19 12H6M12 5l-7 7 7 7" />
                    </svg>
                  </div>
                  <div>
                    <div className="px-1 bg-bgOffWhite">
                      <span>Home</span>
                    </div>
                  </div>
                </div>
              </button>
            </Link>
            {/* Title */}
            {data ? (
              <div className="flex flex-col w-full">
                <Carousel
                  showArrows="true"
                  autoPlay="true"
                  infiniteLoop="true"
                  showThumbs={false}
                  stopOnHover={false}
                  dynamicHeight={true}
                  interval={5000}
                  className="max-w-6xl m-auto">
                  {data.image.map((img, i) => (
                    <div key={i}>
                      <img alt="project-image" className="w-full h-auto" src={data.image[i].url} />
                    </div>
                  ))}
                </Carousel>
                <div className="container flex justify-start items-center py-4 ml-4 text-left">
                  <div className="grid grid-cols-none gap-1">
                    <div>
                      <h1 className="text-4xl mb-4">
                        <b>{data.title}</b>
                      </h1>
                    </div>

                    <div className="flex flex-wrap">
                      {data.tag.map((tag, i) => (
                        <div
                          className="rounded bg-accentColor text-white text-lg text-center mb-2 px-2 mr-2"
                          key={`tag-${i}`}>
                          {tag.name}
                        </div>
                      ))}
                    </div>

                    <div>
                      <h2 className="font-bold text-2xl mt-2 mb-2">Description</h2>

                      <div className="ql-snow ">
                        {' '}
                        <div className="ql-editor" style={{ paddingLeft: 0 }}>
                          {parse(data.desc)}
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className="font-bold text-2xl mt-2 mb-2">Course</h2>
                      <span>
                        {data.courseNo.courseNo} {data.courseNo.courseName}
                      </span>
                    </div>

                    <div>
                      <div className="flex">
                        <h2 className="font-bold text-2xl mt-2 mb-2">Team </h2>
                        <span className="text-xs text-gray-700 ml-2 text-center pt-5">
                          Click on user to find out more
                        </span>
                      </div>
                      {data.peopleInvolved.map((people) => {
                        return (
                          <div className=" flex items-center mb-2" key={people._id}>
                            {/* <span>{people.name} </span> */}
                            <img
                              src={
                                people.avatar === undefined || people.avatar === null
                                  ? emptyAvatar
                                  : `${people.avatar}`
                              }
                              className="rounded-full h-10 w-10 object-cover mr-2 border-2 border-black"
                            />
                            <Link to={`/profile/${people._id}`} className="text-lg">
                              {people.name}{' '}
                            </Link>
                            {/* <span>{people.studentId}</span> */}
                          </div>
                        );
                      })}
                    </div>
                    {data.youtube !== '' && getId(data.youtube) !== null && (
                      <div className=" mb-2">
                        <h3 className="font-bold text-2xl mt-2 mb-2">Youtube: </h3>
                        <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${getId(data.youtube)}`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      </div>
                    )}
                    {(data.github || data.linkIn || data.telegram) && (
                      <div className="mb-6">
                        <h3 className="font-bold text-2xl mt-2 mb-2">Relevant Links: </h3>

                        {data.linkIn && (
                          <a href={data.linkIn} target={'_blank'}>
                            <LinkedInIcon sx={{ fontSize: 40 }} />
                          </a>
                        )}
                        {data.github && (
                          <a href={data.github} target={'_blank'}>
                            <GitHubIcon sx={{ fontSize: 40 }} />
                          </a>
                        )}
                        {data.telegram && (
                          <a href={data.telegram} target={'_blank'}>
                            <TelegramIcon sx={{ fontSize: 40 }} />
                          </a>
                        )}
                      </div>
                    )}

                    <div>
                      <Rating key={projectId} likedBool={data.liked} projectID={projectId} />
                    </div>
                    {data.editable && (
                      <div className="grid grid-flow-col gap-2">
                        <button
                          className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                          onClick={() => {
                            EditPost(projectId);
                          }}>
                          Edit
                        </button>
                        <button
                          className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                          onClick={() => {
                            selectPost(projectId);
                          }}>
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
        <DeletePostDialog
          open={deleteDialogOpen}
          onOk={deletePost}
          handleClose={() => setDeleteDialogOpen(false)}
        />
      </div>
    </>
  ));
}
