import { useEffect, useState, useRef } from 'react';
import * as React from 'react';
import axios from '../axiosConfig'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
// import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import ReactQuill from 'react-quill';

import katex from "katex";
import "katex/dist/katex.min.css";
import { modules, formats } from '../utils/Editor'
import jwt from 'jwt-decode'
import { useNavigate, useLocation } from "react-router-dom";
import useAppContext from '../hooks/useAuth'


window.katex = katex;

export default function Upload() {
  const axiosPrivate = useAxiosPrivate()
  const reactQuillRef = useRef(null)
  const { state } = useAppContext()
  const navigate = useNavigate();
  const { search } = useLocation();
  // const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState([])
  const [courses, setCourses] = useState([])
  const [content, setContent] = useState("")
  const [editMode, setEditMode] = useState(false)

  const [project, setProject] = useState("")
  const [files, setFiles] = useState([])
  const [projectImage, setProjectImage] = useState([])
  const [deletedFiles, setDeletedFiles] = useState([])


  const [alert, setAlert] = useState({
    open: false,
    message: "Error has occurred!"
  })

  const [formValue, setformValue] = useState({
    title: "",
    tag: [],
    courseNo: '',
    term: 1,
    peopleInvolved: [],

    github: '',

    telegram: "",
    linkIn: "",
    youtube: "",
    publish: false
  });

  useEffect(() => {
    async function fetchData() {

      // setLoading(true)
      const query = new URLSearchParams(search)
      if (state) {

        formValue.peopleInvolved = [state.studentId]

      }
      if (query.get('project') != null) {
        const response = await axiosPrivate.get(`/api/posts/${query.get('project')}`)
        const project = response.data
        if (project.editable) {
          setProject(project)
          setEditMode(true)

          const peopleArr = project.peopleInvolved.map(people => people.studentId)


          setformValue({
            title: project.title,
            tag: project.tag,
            courseNo: project.courseNo,
            peopleInvolved: peopleArr,
            term: project.term,
            telegram: project.telegram,
            github: project.github,
            linkIn: project.linkIn,
            youtube: project.youtube,
            publish: project.publish
          })
          setProjectImage(project.image)
          setContent(project.desc)
        }

      }

      // setLoading(false)
    }

    fetchData()

  }, [state])

  useEffect(()=>{
      const getTagCourse = async ()=>{
          const tag = await axios.get('/api/posts/tags')
          const course = await axios.get('/api/posts/courses')
          setTags(tag.data)
          setCourses(course.data)
      }

      getTagCourse()
  },[])

  const handleAdd = () => {

    const newFormValue = { ...formValue }

    newFormValue.peopleInvolved.push('')

    setformValue(newFormValue)
  }

  const handleTag = (event, value) => {
    const newFormValue = { ...formValue }
    // newFormValue.tag = value.map((item) => { return item._id })
    newFormValue.tag = value
    setformValue(newFormValue)
  }

  const handleCourse = (event, value) => {
    const newFormValue = { ...formValue }
    console.log(value)
    newFormValue.courseNo = value
    setformValue(newFormValue)
  }

  const deleteImage = async (i) => {

    setFiles(images => images.filter((_, index) => index !== i))
  }
  const deleteProjectImage = (i) => {
    setDeletedFiles(prevState => {
      const newState = prevState.slice()
      newState.push(projectImage[i].filename)
      return newState
    })
    setProjectImage(images => images.filter((_, index) => index !== i))
  }
  const handleArrayChange = (index, event) => {

    const newFormValue = { ...formValue }
    newFormValue.peopleInvolved[index] = event.target.value

    setformValue(newFormValue)
  }

  const handleContentChange = (content, delta, source, editor) => {
    // console.log(delta)
    // const ops = delta.ops
    // var action
    // if (ops.length == 1) {
    //   action = ops[0]
    // } else if (ops.length === 2) {
    //   action = ops[1]
    // }
    // console.log(editor.getContents())
    // // console.log(editor)
    // // console.log(action.insert)
    // console.log(reactQuillRef.current.getEditor().getText(0, 1))
    // if (action.insert !== undefined && action.insert === '@') {
    //   console.log('insert!!')
    //   reactQuillRef.current.getEditor().insertEmbed(0, 'variable', 'variable name')
    // }
    // const container = reactQuillRef.current.getEditor().container

    // Quill.insertText(1,'abc')
    setContent(content)
  }

  const handleArrayDelete = (index) => {

    const newFormValue = { ...formValue }
    newFormValue.peopleInvolved.splice(index, 1)
    console.log(newFormValue)
    setformValue(newFormValue)
  }

  const handleFileChange = async (event) => {
    event.preventDefault()
    console.log(files.concat(Array.from(event.target.files)))
    setFiles(files.concat(Array.from(event.target.files)))

  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const tagArr = formValue.tag.map(tag => {
      return tag._id
    })

    var dataObject = formValue
    dataObject.courseNo = dataObject.courseNo._id
    dataObject.tag = tagArr
    // dataObject.image = files
    dataObject.desc = content
    const formData = new FormData();


    for (const key of Object.keys(files)) {
      formData.append('file', files[key])
    }

    try {
      if (!editMode) {


        const result = await axiosPrivate.post(`/api/file?dest=projectImage`, formData)
        dataObject.image = result.data
        console.log(result)
        const response = await axiosPrivate({
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',

          },
          data: JSON.stringify(dataObject),
          url: "/api/posts",

        })
        // console.log(response.data)
        if (response.status === 201) {
          navigate(`/projects/${response.data._id}`)
        }
      } else {

        await axiosPrivate.post(`/api/file?project=${project._id}&dest=projectImage`, formData)

        const response = await axiosPrivate({
          method: 'PATCH',

          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify(dataObject),
          url: `/api/posts/${project._id}`,

        })
        // console.log(response.data)
        if (response.status === 200) {
          navigate(`/projects/${project._id}`)
        }
      }


      if (deletedFiles.length > 0) {
        deletedFiles.map(filename => axiosPrivate({
          method: 'delete',
          url: `/api/file/${filename}/${project._id}`
        }))

        await Promise.all(deletedFiles)
      }
    } catch (e) {

      setAlert({ open: true, message: e.response.data })
    }

  }

  const handleChange = (event) => {
    // console.log(event.target.name, event.target.checked)
    if (event.target.name === 'publish') {
      setformValue({
        ...formValue,
        [event.target.name]: event.target.checked
      });
    } else {
      setformValue({
        ...formValue,
        [event.target.name]: event.target.value
      });
    }

  }



  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <form onSubmit={handleSubmit}>
      <Snackbar
        open={!state}
        message="Please Login to upload project"

      >
        <Alert severity="warning">Please Login To Upload Project</Alert>
      </Snackbar>
      <Snackbar open={alert.open}>
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>

      <div className="bg-bgOffWhite py-8 min-h-screen">
        <div className="max-w-6xl mx-auto px-20 ">
          {/* filter 1 */}
          <div className="pt-4">
            <label className="block text-gray-700  text-2xl font-bold mb-2" htmlFor="header">
              Upload Project
            </label>
            <div className="mb-6" />
            <div className="flex items-start">
              <label className="block text-gray-700 text-lg font-bold mb-2 mr-1" htmlFor="title">
                Title
              </label>
              {/* <Tooltip placement='right' title='Title of your project'>
                <div >

                  <HelpOutlineIcon fontSize='small' />
                </div>

              </Tooltip> */}
            </div>

            {/* <label className="block text-gray-400 text-sm italic font-normal mb-2" htmlFor="desc">
              Please keep it to 6 words.
            </label> */}
            {/* <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="title"
              type="text"
              name='title'
              value={formValue.title}
              onChange={handleChange}
              placeholder="Type here"
              required
            /> */}
            <TextField id="outlined-basic" label="Title" name='title' value={formValue.title} variant="outlined" required onChange={handleChange} />

          </div>
          <div className="mb-6" />
          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="images">
            Images
          </label>
          <label className="block text-gray-400 text-sm italic font-normal mb-2" htmlFor="desc">
            First image will be the cover image. (JPEG and PNG only)
          </label>
          {(files.length > 0 || projectImage.length > 0) && (
            <div className='flex overflow-x-scorll mb-2 w-full'>

              {projectImage.map((image, i) => {
                return (
                  <div className='relative w-1/5 h-auto mr-2 group'
                  >
                    <img
                      key={i}
                      alt="project"
                      id={`image-${i}`}
                      onClick={() => deleteImage(i)}
                      src={image.url}
                      className='group-hover:opacity-20 h-full w-full object-cover'
                    />
                    <span
                      className='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 cursor-pointer text-accentColor hidden group-hover:block'

                      id={`button-${i}`}
                      onClick={() => deleteProjectImage(i)}
                    >
                      Remove
                    </span>
                  </div>
                )
              })}
              {files.map((data, i) => {

                return (
                  <div className='relative w-1/5 h-auto mr-2 group'

                  >
                    <img
                      key={i}
                      alt="project"
                      id={`image-${i}`}
                      onClick={() => deleteImage(i)}
                      src={URL.createObjectURL(files[i])}
                      className='group-hover:opacity-20 h-full w-full object-cover'
                    />
                    <span
                      className='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 cursor-pointer text-accentColor hidden group-hover:block'

                      id={`button-${i}`}
                      onClick={() => deleteImage(i)}
                    >
                      Remove
                    </span>
                  </div>
                )

              })}

            </div>
          )}
          <div className="flex justify-left">
            <div className="mb-3 w-96">
              <label htmlFor="contained-button-file">
                <input className='hidden' accept="image/*" id="contained-button-file" multiple type="file" onChange={handleFileChange} name='file' />

                <button
                  onClick={() => { document.getElementById('contained-button-file').click() }}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                >
                  Add another
                </button>
              </label>

            </div>
          </div>

          <div className="mb-6" />
          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="project desc">
            Description
          </label>
          <label className="block text-gray-400 text-sm italic font-normal mb-2" htmlFor="desc">
            Brief description of your project.
          </label>
          {/* <EditorToolbar /> */}
          <ReactQuill
            ref={reactQuillRef}
            defaultValue={content}
            value = {content}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            theme={"snow"}
          ></ReactQuill>


          <div className="mb-6" />
          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="members">
            People Involved
          </label>
          <label className="block text-gray-400 text-sm italic font-normal mb-4" htmlFor="desc">
            Student IDs of your group members. (Student IDs that is not in the system will be ignored)
          </label>

          {formValue.peopleInvolved.map((element, index) => {

            return (
              <div className='mb-4 flex flex-row' key={index}>
                {/* <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="title"
                  type="number"
                  value={formValue.peopleInvolved[index]}
                  onChange={(event) => { handleArrayChange(index, event) }}
                  placeholder="Type here"
                /> */}
                <TextField
                  id="number"
                  label="Student ID"
                  type="number"
                  onChange={(event) => { handleArrayChange(index, event) }}
                  value={formValue.peopleInvolved[index]}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {
                  index !== 0 && <button
                    onClick={() => { handleArrayDelete(index) }}
                    name="remove"
                    id="'removebtn"
                    className="text-accentColor ml-4"
                    type="button"
                  >
                    Remove
                  </button>
                }

              </div>
            )
          })}

          <button
            onClick={handleAdd}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Add another
          </button>
          <div className="mb-6" />

          <div className="mb-6" />
          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="term">
            Term
          </label>
          <FormControl fullWidth>
            <InputLabel id="term-select-label">Term</InputLabel>
            <Select
              labelId="Term"
              id="Term"
              name='term'
              value={formValue.term}
              label="Term"
              onChange={handleChange}
              required
            >

              {[...Array(8)].map((e, i) => {

                return (
                  <MenuItem value={i + 1} key={i}>{i + 1}</MenuItem>
                )
              })
              }
              <MenuItem value={9} >Others</MenuItem>
            </Select>
          </FormControl>

          <div className="mb-6" />
          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="title">
            Course
          </label>
          <Autocomplete
            fullWidth
            autoHighlight
            id="course-select"
            
            options={courses}
            // isOptionEqualToValue={(option, value) => option._id === value._id}
            value={formValue.courseNo ? formValue.courseNo : null}
            getOptionLabel={option => `${option.courseName} (${option.courseNo})`}
            onChange={handleCourse}
            isOptionEqualToValue={(option,value)=>option._id===value._id}
            renderInput={params => (
              <TextField {...params} label="Course" required />
            )}
          />

          <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="title">
            Tag
          </label>
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            options={tags}
            // defaultValue={formValue.tag}
            //TODO FIX THE DEFAULT VALUE
            // formvalue.tag set to project.tag it displays correctly but during submit will face project cos tag object is submitted instead of tag id
            //if set the default value to project.tag doesnt work cos initally project is empty i think
            value={formValue.tag}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option,value)=>option._id===value._id}
            

            // defaultValue={ }
            renderInput={(params) => (
              <TextField {...params}
                label="Tags"
              />
            )}
            onChange={handleTag}

            sx={{ width: '500px' }}
          />
          {/* <div className="mt-8">
            <TextField

              id="date"
              label="Start Date"
              name='startDate'
              type="date"
              onChange={handleChange}
              defaultValue="2017-05-24"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            /></div>
          <div className="mt-8">
            <TextField

              id="date"
              label="End Date"
              name='endDate'
              type="date"
              onChange={handleChange}
              defaultValue="2017-05-24"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            /></div> */}
          <label className="block text-gray-700 text-lg font-bold mt-6" htmlFor="youtube">
            Youtube Link
          </label>
          <div>
            <TextField id="outlined-basic" label="Youtube" name='youtube' variant="outlined" value={formValue.youtube} onChange={handleChange} />
          </div>

          <label className="block text-gray-700 text-lg font-bold mt-6" htmlFor="contact">
            Contact
          </label>
          <div className='grid grid-cols-2 gap-2'>

            <TextField id="outlined-basic" label="Telegram" name='telegram' variant="outlined" value={formValue.telegram} onChange={handleChange} />
            <TextField id="outlined-basic" label="Github" name='github' variant="outlined" value={formValue.github} onChange={handleChange} />

            <TextField id="outlined-basic" label="LinkedIn" name='linkIn' variant="outlined" value={formValue.linkIn} onChange={handleChange} />

          </div>
          <FormControlLabel control={<Switch name='publish' onChange={handleChange} checked={formValue.publish} />} label="Publish" />
          <div className="flex items-center justify-between mt-6">
            <button type="Submit" className={`${!state ? "bg-gray-700" : "bg-red-500 hover:bg-red-700"}  text-white font-bold  py-2 px-4 rounded focus:outline-none focus:shadow-outline`} disabled={!state} >{editMode ? "Save Changes" : "Submit"}</button>
          </div>
        </div>
      </div>
    </form>
  );
}
