import axios from '../axiosConfig'
import useAppContext from '../hooks/useAuth'
import {useEffect} from 'react'
const useRefreshToken = ()=>{
  const {setToken} = useAppContext()
  const refresh = async()=>{
    const response = await axios.get('/api/user/jwt/refresh',{
      withCredentials:true
    })
    setToken(prevState=>(response.data.Token))

    return response.data.Token

  }
  return refresh
  
}
export default useRefreshToken;
