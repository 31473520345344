import React, { useEffect, useState } from "react";
import { useUser } from "../../utils/useUser";
import { useNavigate } from "react-router-dom";
// import axios from 'axios'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import MuiAlert from "@mui/material/Alert";
import emptyAvatar from "../../images/icons/empty_avatar.png";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const d = new Date();
const currentYear = d.getFullYear();
let years = ["Select your graduating year"];
for (let i = currentYear; i < currentYear + 10; i++) {
  years.push(i);
}

const classYears = years;

const EditProfilePage = () => {
  const axiosPrivate = useAxiosPrivate();
  let navigate = useNavigate();
  const { state, setState } = useUser({ redirecTo: "/" });

  const [alertDisplay, setAlertDisplay] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    studentId: 0,
    _id: "",
    aboutMe: "",
    class_of: 0,
    pillar: null,
    avatar: null,
    telegram: "",
    linkIn: "",
    github: "",
  });

  const [filesToDelete, setFilesToDelete] = useState([]);

  useEffect(() => {
    if (state) {
      // axiosPrivate.get(`/api/user/${state._id}`)
      //   .then(res => {
      //     setUser(prevState => ({
      //       ...prevState,
      //       ...res.data.user
      //     }))
      //   })
      //   .catch(err => console.log(err))
      setUser(state);
    }
  }, [state]);

  const handleFileChange = async (event) => {
    event.preventDefault();

    setError("");
    setAlertDisplay(false);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);

    try {
      // Add new image
      const response = await axiosPrivate({
        method: "post",
        url: "/api/file?dest=avatar",
        data: formData,
      });

      console.log(response.data);
      // const { filename } = response.data[0]

      // setFilesToDelete((prevState) => prevState.concat([user.avatar]));
      setUser((prevState) => ({
        ...prevState,
        avatar: response.data[0].url,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const handleUserChange = (e) => {
    e.preventDefault();

    setError("");
    setAlertDisplay(false);
    const { name, value } = e.target;

    if (name === "aboutMe" && value.split(" ").length > 100) return;

    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setError("");
    setAlertDisplay(false);
    setLoading(true);

    try {
      const {
        name,
        aboutMe,
        class_of,
        pillar,
        avatar,
        telegram,
        linkIn,
        github,
      } = user;

      if (!name) setError("Please enter your name");
      else if (!aboutMe) setError("Please fill tell us about yourself");
      else if (isNaN(class_of)) setError("Please select your graduating year");
      else if (pillar === "select") setError("Please select your pillar");
      else {
        await axiosPrivate({
          method: "patch",
          url: "/api/user",
          data: {
            name,
            aboutMe,
            class_of,
            pillar,
            avatar,
            telegram,
            linkIn,
            github,
          },
        });

        // if (filesToDelete.length > 0) {
        //   const promises = filesToDelete.map((filename) => {
        //     return axiosPrivate({
        //       method: "delete",
        //       url: `/api/file/${filename}`,
        //     });
        //   });
        //
        //   await Promise.all(promises);
        // }

        setLoading(false);
        setAlertDisplay(true);
        setState((prevState) => ({
          ...prevState,
          user,
        }));
      }
    } catch (error) {
      const errMsg = error.response.data;
      if (errMsg === "user doesn't exist") setError("User doesn't exist");
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <div className="bg-bgOffWhite px-8">
      <div className="flex flex-col py-8 max-w-4xl mx-auto">
        <button
          className="bg-black  hover:bg-gray-400 textBlack text-lg inline-flex items-center border border-black w-24"
          onClick={() => navigate(`/profile/${state._id}`)}
        >
          <div className="grid grid-cols-2 gap-0">
            <div className="bg-black flex items-center hover:bg-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
              >
                <path d="M19 12H6M12 5l-7 7 7 7" />
              </svg>
            </div>
            <div>
              <div className="px-1 bg-bgOffWhite">
                <span>Back</span>
              </div>
            </div>
          </div>
        </button>

        <form className="mt-8 px-8 flex flex-col" onSubmit={handleFormSubmit}>
          <h1 className="font-bold text-4xl">Avatar*</h1>
          <img
            src={user.avatar ? `${user.avatar}` : emptyAvatar}
            className="border-2 border-black h-64 w-64 mx-auto rounded-full my-4 object-cover"
          />
          <div className="mt-8 flex">
            <label
              htmlFor="avatar-upload"
              className="mb-8 mx-auto py-2 px-4 rounded bg-accentColor text-white hover:cursor-pointer"
            >
              Change Avatar
            </label>
          </div>
          <input
            id="avatar-upload"
            type="file"
            name="avatarImage"
            onChange={handleFileChange}
            placeholder="Change Image"
            className="hidden"
          />

          {/* Name */}
          <h1 className="font-bold text-4xl">Name*</h1>
          <input
            type="text"
            className="p-2 w-full mb-8"
            placeholder="Enter name here"
            name="name"
            value={user.name}
            onChange={handleUserChange}
          />

          {/* Class of */}
          <h1 className="font-bold text-4xl">Class of*</h1>
          <select
            className="w-full p-2 mb-8"
            name="class_of"
            value={user.class_of ? user.class_of : classYears[0]}
            onChange={handleUserChange}
          >
            {classYears.map((year) => (
              <option value={year} key={year}>
                {year}
              </option>
            ))}
          </select>

          {/* Pillar */}
          <h1 className="font-bold text-4xl">Pillar*</h1>
          <select
            className="w-full p-2 mb-8"
            name="pillar"
            value={user.pillar ? user.pillar : "select"}
            onChange={handleUserChange}
          >
            <option value="select">Select your pillar</option>
            <option value="ASD">ASD</option>
            <option value="DAI">DAI</option>
            <option value="ESD">ESD</option>
            <option value="EPD">EPD</option>
            <option value="ISTD">ISTD</option>
          </select>

          {/* Telegram */}
          <h1 className="font-bold text-4xl">Telegram</h1>
          <h1 className="text-slate-500 italic mb-1">
            Optional, if you wish to be contactable
          </h1>
          <input
            type="text"
            className="p-2 w-full mb-8"
            placeholder="e.g. t.me/username"
            name="telegram"
            value={user.telegram}
            onChange={handleUserChange}
          />

          {/* LinkedIn */}
          <h1 className="font-bold text-4xl">LinkedIn</h1>
          <h1 className="text-slate-500 italic mb-1">
            Optional, if you wish to be contactable
          </h1>
          <input
            type="text"
            className="p-2 w-full mb-8"
            placeholder="e.g. linkedin.com/in/yourfullname"
            name="linkIn"
            value={user.linkIn}
            onChange={handleUserChange}
          />
          {/* Github */}
          <h1 className="font-bold text-4xl">Github</h1>
          <h1 className="text-slate-500 italic mb-1">
            Optional, if you wish to be contactable
          </h1>
          <input
            type="text"
            className="p-2 w-full mb-8"
            placeholder="e.g. github/username"
            name="github"
            value={user.github}
            onChange={handleUserChange}
          />

          {/* About Me */}
          <h1 className="font-bold text-4xl mt-8">About Me*</h1>
          <div className="flex flex-row">
            <h1 className="text-slate-500 italic mb-1">Maximum 100 words</h1>
            {user.aboutMe && (
              <h1 className="text-slate-500 italic mb-1 mr-0 ml-auto">
                {user.aboutMe.split(" ").length}/100
              </h1>
            )}
          </div>
          <textarea
            type="text"
            className="p-2 h-32 w-full mb-8"
            placeholder="Enter more about yourself here"
            name="aboutMe"
            value={user.aboutMe}
            onChange={handleUserChange}
          />

          {alertDisplay && (
            <Alert variant="filled" severity="success">
              Your profile has been updated!
            </Alert>
          )}
          {error && (
            <Alert variant="filled" severity="error">
              {error}
            </Alert>
          )}

          <button
            type="submit"
            className="self-end px-4 py-2 mt-8 text-white bg-accentColor rounded"
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfilePage;
