import '../css/personalCard.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';

function PersonalCard({ name, desc, github, role, linkedin, email, image }) {
  return (
    <div className="flex flex-col mx-4">
      {/* <div className='container relative flex items-center mb-20' ref={listInnerRef}> */}
      {/*     <div className="hex relative w-64 h-72 "> */}
      {/*         <div className="shape absolute w-full h-full top-0 left-0 bg-black pentagon-clip-path "> */}
      {/*             <img src={image} className="object-cover absolute top-0 left-0 w-full h-full" /> */}
      {/*             <div className="content flex absolute justify-center items-center  w-full h-full top-0 left-0 flex-col text-textWhite"> */}
      {/*                 <h2 className='text-2xl mb-2'>{name}</h2> */}
      {/*                 <p className='px-2 text-center tracking-tighter h-1/4 overflow-scroll'>{desc}</p> */}
      {/*                 <div className="flex items-center justify-evenly w-full mt-2"> */}
      {/*                     <a href={github} target="_blank" rel="noopener noreferrer" className="text-white hover:text-black"> */}
      {/*                         <GitHubIcon /> */}
      {/*                     </a> */}
      {/*                     <a href={linkedin} target="_blank" rel="noopener noreferrer" className="text-white hover:text-black"> */}
      {/*                         <LinkedInIcon /> */}
      {/*                     </a> */}
      {/*                     <a href={email} target="_blank" rel="noopener noreferrer" className="text-white hover:text-black"> */}
      {/*                         <EmailIcon /> */}
      {/*                     </a> */}
      {/*                 </div> */}
      {/*             </div> */}
      {/*         </div> */}
      {/*     </div> */}
      {/**/}
      {/* </div> */}

      {/* <div className='container relative flex justify-center items-center mb-28'>
                <div className="hex relative w-64 h-72 ">
                    <div className="shape relative w-full h-full top-0 left-0 bg-black pentagon-clip-path ">
                        <img src={image} className="object-cover absolute top-0 left-0 w-full h-full" />

                    </div>
                </div>
                <div className="content flex ml-10 relative justify-center items-center  w-3/5 h-full top-0 left-0 flex-col text-textBlack text-center">
                    <div className='w-full text-3xl tracking-tigher'>{name}</div>
                    <div className='w-full mb-4 text-lg tracking-wider text-accentColor '>Backend Developer</div>
                    <p className=' '>{desc}</p>
                    <div className="flex items-center justify-center w-full mt-4">
                        <a href={github} target="_blank" rel="noopener noreferrer" className='mr-2'>
                            <GitHubIcon fontSize='large' />
                        </a>
                        <a href={linkedin} target="_blank" rel="noopener noreferrer" className='mr-2' >
                            <LinkedInIcon fontSize='large' />
                        </a>
                        <a href={email} target="_blank" rel="noopener noreferrer" >
                            <EmailIcon fontSize='large' />
                        </a>
                    </div>
                </div>

            </div>*/}

      <div className="container relative flex justify-center items-center mb-20">
        {image && (
          <div className="hex relative w-64 h-72 ">
            <div className="shape relative w-full h-full top-0 left-0 bg-black pentagon-clip-path ">
              <img src={image} className="object-cover absolute top-0 left-0 w-full h-full" />
            </div>
          </div>
        )}

        <div className="content flex ml-10 relative justify-center items-center  w-3/5 h-full top-0 left-0 flex-col text-textBlack text-center">
          <div className="w-full text-3xl tracking-tigher">{name}</div>
          <div className="w-full mb-4 text-lg tracking-wider text-accentColor ">{role}</div>
          <p className=" ">{desc}</p>
          <div className="flex items-center justify-center w-full mt-4">
            {github && (
              <a href={github} target="_blank" rel="noopener noreferrer" className="mr-2">
                <GitHubIcon fontSize="large" />
              </a>
            )}
            <a href={linkedin} target="_blank" rel="noopener noreferrer" className="mr-2">
              <LinkedInIcon fontSize="large" />
            </a>
            {email && (
              <a href={`mailto:${email}`} rel="noopener noreferrer">
                <EmailIcon fontSize="large" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalCard;
