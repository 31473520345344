import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import useAppContext from '../../hooks/useAuth'
import { Carousel } from "react-responsive-carousel"
import parse from 'html-react-parser'
// import axios from 'axios'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import '../../css/hexagon.css'
import Rating from '../Rating'
import MuiAlert from '@mui/material/Alert';
import DeletePostDialog from '../Utils/DeletePostDialog'

import emptyAvatar from '../../images/icons/empty_avatar.png'
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
const Alert = React.forwardRef(function Alert(props, ref) {
  
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ProfilePage = () => {
  const axiosPrivate = useAxiosPrivate()

  let navigate = useNavigate()
  const { userId } = useParams()
  const { state, setState } = useAppContext()

  const [alertDisplay, setAlertDisplay] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedPost, setSelectedPost] = useState("")

  const [user, setUser] = useState({
    name: "",
    email: "",
    studentId: 0,
    _id: "",
    aboutMe: "",
    class_of: 0,
    pillar: null,
    avatar: null,
    telegram: "",
    linkIn: "",
  })
  const [projects, setProjects] = useState()

  useEffect(() => {
    const getUserData = async () => {
      try {
        let config = {
          method: 'get',
          url: `/api/user/${userId}`
        }

        const response = await axiosPrivate(config)

        setUser(prevState => ({ ...prevState, ...response.data}))
        const projects = await axiosPrivate.get(`/api/posts/user/${userId}`)
        console.log(projects)
        setProjects(projects.data)
      } catch (error) {
        console.log(error.response.data)
      }
    }

    getUserData()
  } ,[])

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(user.email)
    setAlertDisplay(true)
  }

  const selectPost = (id) => {
    setDeleteDialogOpen(true)
    setSelectedPost(id)
  }

  const deletePost = async () => {
    try {
      await axiosPrivate({
        method: 'delete',
        url: `/api/posts/${selectedPost}`,
      })

      setProjects(prevState => prevState.filter(project => project._id !== selectedPost))
      setDeleteDialogOpen(false)
      setState(prevState => ({
        ...prevState,
        projects: prevState.projects.filter(project => project._id !== selectedPost)
      }))
    } catch (error) {
      if (error.response.status === 204) {
        window.location.reload(false)
      }
    }
  }

  return (
    <div className='bg-bgOffWhite px-8'>
      <div className='flex flex-col py-8 max-w-4xl min-h-screen mx-auto'>
        <button
          className="bg-black  hover:bg-gray-400 textBlack text-lg inline-flex items-center border border-black w-24 mb-4"
          onClick={() => navigate('/', { replace: true })}
        >
          <div className="grid grid-cols-2 gap-0">
            <div className="bg-black flex items-center hover:bg-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#FFFFFF"
                strokeWidth="2"
                strokeLinecap="round"
              >
                <path d="M19 12H6M12 5l-7 7 7 7" />
              </svg>
            </div>
            <div>
              <div className="px-1 bg-bgOffWhite">
                <span>Back</span>
              </div>
            </div>
          </div>
        </button>

        {alertDisplay && <Alert severity="info">Email has been copied to your clipboard!</Alert>}

        {/* Basic Info */}
        {/* <div className='flex flex-row mt-12'>
          <img
            src={(user.avatar === null) ? emptyAvatar : `${user.avatar}`}
            className="rounded-full h-80 w-80 object-cover mr-16 border-2 border-black shadow-md"
          />
          <div className='flex flex-col justify-between'>
            <h1 className='text-5xl font-bold'>{user.name}</h1>
            <h1 className=' text-3xl font-bold'>Class of {(user.class_of === 0) ? 'Unknown' : user.class_of}</h1>
            <h1 className=' text-3xl font-bold'>{(user.pillar === null) ? 'Pillar Unknown' : user.pillar}</h1>
            <div className='grid grid-cols-3 gap-8'>
              <img src={envelopeIcon} onClick={() => copyEmailToClipboard()} className="h-16 hover:cursor-pointer" />
              {user.telegram &&
                <a href={`${user.telegram}`} target="_blank">
                  <img src={telegramIcon} className="h-16 hover:cursor-pointer" />
                </a>
              }
              {user.linkIn &&
                <a href={`${user.linkIn}`} target="_blank">
                  <img src={linkedinIcon} className="h-16 hover:cursor-pointer" />
                </a>
              }
            </div>
          </div>
          {state && (state.user._id === userId &&
            <Link to='/edit/profile' className='mr-0 ml-auto'>
              <PenToSquareIcon tailwind='h-12 hover:cursor-pointer hover:fill-accentColor' />
            </Link>
          )}
        </div>

        {/* About Me */}
        {/* <div className='flex flex-col mt-16'>
          <h1 className='text-5xl font-bold'>About Me</h1>
          <p className='text-lg'>{(user.aboutMe === "") ? 'null' : user.aboutMe}</p>
        </div> */}

        <div className='container relative flex justify-center items-center mb-20 mt-10'>
          <div className="hex relative w-64 h-72 ">
            <div className="shape relative w-full h-full top-0 left-0 bg-black pentagon-clip-path ">
              <img src={(user.avatar === null) ? emptyAvatar : `${user.avatar}`} className="object-cover absolute top-0 left-0 w-full h-full" />

            </div>
          </div>
          <div className="content flex ml-10 relative justify-center items-center  w-3/5 h-96 pt-10 top-0 left-0 flex-col text-textBlack text-center">
            <div className='w-full text-3xl tracking-tigher'>{user.name}</div>
            {/* <div className='w-full mb-4 text-lg tracking-wider text-accentColor '>Backend Developer</div> */}
            {user.aboutMe && (<p className='font-md mt-4 overflow-scroll h-64'>{user.aboutMe}</p>)}


            <div className="flex items-center justify-center w-full mt-4">
              {user.github && (<a href={user.github} target="_blank" rel="noopener noreferrer" className='mr-2'>
                <GitHubIcon fontSize='large' />
              </a>)}
              {user.linkIn && (<a href={user.linkIn} target="_blank" rel="noopener noreferrer" className='mr-2' >
                <LinkedInIcon fontSize='large' />
              </a>)}
              {user.telegram && (<a href={user.telegram} target="_blank" rel="noopener noreferrer" >
                <TelegramIcon fontSize='large' />
                {/* <img src={telegramIcon} className="h-16 hover:cursor-pointer" /> */}
              </a>)}
            </div>
            {state && (state._id === userId &&
              <Link to='/edit/profile'
                className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded mt-4"
              ><p>Edit</p> </Link>
            )}
          </div>
        </div>
        <div className='flex flex-col mt-16'>
          <h1 className='text-5xl font-bold mb-4'>Projects</h1>
          {!projects ?
            <p className='text-lg mt-8'>User currently has no projects</p>
            :
            <>
              {projects.map(project => (
                <div className='flex flex-col mb-10 rounded-md border border-slate-300 shadow-md' key={project._id} >
                  <Link to={`/projects/${project._id}`}>
                    <Carousel
                      showArrows="true"
                      autoPlay="true"
                      infiniteLoop="true"
                      showThumbs={false}
                      stopOnHover={false}
                      dynamicHeight={true}
                      interval={5000}
                      className="max-w-6xl m-auto"
                    >
                      {project.image.map((data, i) => (
                        <div key={i}>
                          <img
                            alt="project-image"
                            className="object-cover object-center w-full"
                            style={{ height: '32rem' }}
                            src={data.url}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </Link>
                  <div className='flex flex-col p-8' style={{ backgroundColor: "#C1BEBE" }}>
                    <Link to={`/projects/${project._id}`}>
                      <h1 className='font-bold text-3xl mb-4'>{project.title}</h1>
                    </Link>
                    <div className="relative mb-4 text-textBlack h-32 overflow-hidden">
                      <div>{parse(project.desc)}</div>
                      <div className="absolute bottom-0 w-full h-8 bg-gradient-to-b from-transparent to-filterHover" />
                    </div>
                    <div className='flex flex-row justify-between'>
                      <div className='w-11/12 flex flex-wrap'>
                        {project.tag.map(tag => (
                          <h1 key={tag._id} className='text-lg rounded-lg px-3 mb-2 mr-2' style={{ backgroundColor: "#E5E5E5" }}>{tag.name}</h1>
                        ))}
                      </div>
                      <div className='flex flex-row'>
                        <Rating
                          projectID={project._id}
                          likedBool={project.liked}
                          count={project.upvoteCount}
                        />
                      </div>
                    </div>
                    <div className='flex flex-row mt-4'>

                      {project.editable &&
                        (<div className="grid grid-flow-col gap-2 mx-auto">
                          <button
                            className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                            onClick={() => navigate(`/upload?project=${project._id}`)}
                          >
                            Edit
                          </button>
                          <button
                            className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                            onClick={() => selectPost(project._id)}
                          >
                            Delete
                          </button>
                        </div>)
                      }
                    </div>
                  </div>

                </div>
              ))}
            </>
          }
          <DeletePostDialog
            open={deleteDialogOpen}
            onOk={deletePost}
            handleClose={() => setDeleteDialogOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
