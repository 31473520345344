import React, { useState } from "react";
import { Link } from "react-router-dom";
import useAppContext from "../hooks/useAuth";
import "../index.css";
import "reactjs-popup/dist/index.css";
import Login from "./Login";
import emptyAvatar from "../images/icons/empty_avatar.png";
import { axiosPrivate } from "../axiosConfig";
import Register from "./Register";
import RootLogo from "../images/root-logo-big.png";
import { useSpring, animated } from "react-spring";
export default function Navbar() {
  const { state, token, setState, setToken } = useAppContext();

  const [burgered, setBurgered] = useState(false);

  const props = useSpring({
    to: { y: 0, opacity: 1 },
    from: { y: -10, opacity: 0 },
    reset: true,
  });

  const logout = async () => {
    await axiosPrivate.get("api/user/jwt/logout");
    setState(null);
    setToken("");
  };

  return (
    // md:sticky makes it stick

    <header className="md:sticky bg-bgOffWhite">
      <div className="text-black-400 max-w-7xl m-auto ">
        <nav className=" flex justify-between items-center py-4">
          <Link to="/">
            <img
              src={RootLogo}
              className="w-12 sm:w-12 md:w-16 h-auto mx-0 my-auto"
            ></img>
          </Link>
          <nav className="hidden md:flex ">

            <Link
              className="mr-10 hover:text-accentColor hover:underline"
              to="/"
            >
              Home
            </Link>
            <Link
              className="mr-10 hover:text-accentColor hover:underline"
              to="/upload"
            >
              Upload
            </Link>
            <Link className="mr-10 hover:text-accentColor hover:underline" to="/about">About Us</Link>
            <a
              href="https://kellief0rnia.notion.site/eaa1c2db36a94b1f892b7f3e91f8ae2d?v=b565d3227b314fb3b4cfa40e02ec346d"
              target={"_blank"}
              className="mr-10 hover:text-accentColor hover:underline"
            >
              Feedback
            </a>

            {/* {state && <Link className="mr-10 hover:text-accentColor hover:underline" to={`/profile/${state.user._id}`}>Profile</Link>} */}
            {/* <Link className="mr-10 hover:text-accentColor hover:underline" to="/about" >About</Link> */}
            {!state ? (
              <>
                <Login />
                <span className="px-2">|</span>
                <Register />
              </>
            ) : (
              <>
                {/* <FontAwesomeIcon className="self-center" icon={faUserCircle} /> */}
                <div className="flex items-center">
                  <img
                    src={
                      state.avatar === null || state.avatar === undefined
                        ? emptyAvatar
                        : `${state.avatar}`
                    }
                    className="rounded-full h-5 w-5 object-cover border-2 border-black"
                  />
                  <Link
                    className="pl-2 hover:text-accentColor hover:underline"
                    to={`/profile/${state._id}`}
                  >
                    {state.name}
                  </Link>
                </div>
                {/* <span className="pl-2 font-bold">{state.user.name}</span> */}
                <span className="px-1">|</span>
                <button
                  className="hover:underline hover:text-accentColor"
                  onClick={logout}
                >
                  Logout
                </button>
              </>
            )}
          </nav>

          <div className="absolute right-0 mr-4">
            <button
              className="md:hidden"
              onClick={() => setBurgered(!burgered)}
            >
              <svg
                className="w-6 h-6 text-black hover:text-grey"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d={
                    burgered
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                ></path>
                {/* "M6 18L18 6M6 6l12 12" make it crossy*/}
              </svg>
            </button>
          </div>
        </nav>
        {/* Burger Menu */}

        {burgered && (
          <div className="relative z-10">
            <animated.div style={props}>
              <nav className="flex flex-col bg-bgOffWhite items-end absolute right-0 border-slate-800 border-2 mr-2 rounded-md ">
                {!state ? (
                  <div className="px-2">
                    <Login />
                    <span className="px-2">|</span>
                    <Register />
                  </div>
                ) : (
                  <>
                    {/* <FontAwesomeIcon className="self-center" icon={faUserCircle} /> */}
                    <div className="flex items-center px-2 ">
                      <img
                        src={
                          state.avatar === null || state.avatar === undefined
                            ? emptyAvatar
                            : `${state.avatar}`
                        }
                        className="rounded-full h-5 w-5 object-cover border-2 border-black"
                      />
                      <Link
                        className="pl-2 hover:text-accentColor hover:underline"
                        to={`/profile/${state._id}`}
                      >
                        {state.name}
                      </Link>
                      <span className="px-1">|</span>
                      <button
                        className="hover:underline hover:text-accentColor"
                        onClick={logout}
                      >
                        Logout
                      </button>
                    </div>
                    {/* <span className="pl-2 font-bold">{state.user.name}</span> */}
                  </>
                )}
                <Link
                  className=" hover:text-accentColor px-2 hover:underline"
                  to="/"
                >
                  Home
                </Link>
                <Link
                  className="hover:text-accentColor hover:underline px-2"
                  to="/upload"
                >
                  Upload Project
                </Link>
                <Link className="mr-5 hover:text-black" to="/about">About Us</Link>
                <a
                  href="https://kellief0rnia.notion.site/eaa1c2db36a94b1f892b7f3e91f8ae2d?v=b565d3227b314fb3b4cfa40e02ec346d"
                  target={"_blank"}
                  className=" hover:text-accentColor hover:underline px-2"
                >
                  Feedback
                </a>
              </nav>
            </animated.div>
          </div>
        )}

      </div>
    </header>
  );
}

{
  /* <div class="md:hidden flex items-center">
						<button class="outline-none mobile-menu-button">
						<svg class=" w-6 h-6 text-gray-500 hover:text-green-500 "
							x-show="!showMenu"
							fill="none"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path d="M4 6h16M4 12h16M4 18h16"></path>
						</svg>
					</button>
					</div> */
}

//   <header className="bg-black-800 md:sticky top-0 z-10">
//   <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
//     <div className="title-font font-medium text-black mb-4 md:mb-0">
//       <a href="/" className="ml-3 text-xl">
//         SUTD Project Finder
//       </a>
//     </div>
//     <nav className="hidden md:block md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap items-center text-base justify-center">
//       <a href="/projects" className="mr-5 hover:text-white">
//         Projects
//       </a>
//       <a href="/about" className="mr-5 hover:text-white">
//         About
//       </a>
//       <a href="/contact" className="mr-5 hover:text-white">
//         Contact Us
//       </a>
//       <Login />
//     </nav>

//   </div>
// </header>
