import Popup from 'reactjs-popup';
import { useState } from 'react';
import '../index.css';
import Register from './Register';
import useAppContext from '../hooks/useAuth';
import axios from '../axiosConfig';
import jwt from 'jwt-decode';
import validateEmail from '../utils/validateEmail';

export default function Login() {
  const { setState, setToken } = useAppContext();
  const [visibility, setVisibility] = useState(false);
  const [error, setError] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [verificationKey, setVerificationKey] = useState('');
  const [otp, setOtp] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  // Forget Password
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [doubleNewPassword, setDoubleNewPassword] = useState('');
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  async function login() {
    var config = {
      method: 'post',
      url: '/api/user/login',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        email: email,
        password: password
      }
    };

    setError('');
    setIsLoading(true);

    try {
      if (!email) setError('Please enter your email');
      else if (!validateEmail(email)) setError('Please enter a valid email');
      else if (!password) setError('Please enter your password');
      else {
        const res = await axios(config);
        const { Status, Details } = res.data;

        if (Status == 'Success') {
          setVerificationKey(Details);
          setIsLoading(false);
        }
      }
    } catch (error) {
      const errMsg = error.response.data;
      if (errMsg === "user doesn't exist") setError("User doesn't exist");
      else if (errMsg === 'Invalid password') setError('Invalid credentials');
    }

    setIsLoading(false);
  }

  async function verifyLogin() {
    const config = {
      method: 'post',
      url: '/api/user/verify/otp',
      data: {
        verification_key: verificationKey,
        otp: parseInt(otp),
        check: email
      },
      withCredentials: true
    };

    setError('');
    setIsLoading(true);

    try {
      if (!otp) setError('Please enter your OTP');
      else if (isNaN(parseInt(otp))) setError('Please enter a valid OTP');
      else {
        const res = await axios(config);
        const { Status } = res.data;
        if (Status === 'Success') {
          if (res.data.Token) {
            console.log('setting data');
            setToken(res.data.Token);
            console.log('set');
            setVisibility(false);
            console.log('set visibility');
          }
        }
      }
    } catch (error) {
      console.log(error);
      const { Status, Details } = error.response.data;

      if (Status === 'Failure') {
        if (Details === 'OTP NOT Matched') setError('OTP does not match');
        else if (Details === 'OTP Expired') setError('OTP has expired');
        else if (Details === 'OTP Already Used') setError('OTP has already been used');
        else {
          setError('Bad Request!');
        }
      }
    }

    setIsLoading(false);
  }

  async function forgetPassword() {
    const config = {
      method: 'post',
      url: '/api/user/email/otp',
      data: { email, type: 'FORGET' }
    };

    setError('');
    setIsLoading(true);

    try {
      if (!email) setError('Please enter your email');
      else if (!newPassword) setError('Please enter your new password');
      else if (!doubleNewPassword) setError('Please confirm your new password');
      else if (newPassword !== doubleNewPassword) setError('Passwords do not match');
      else {
        const res = await axios(config);

        const { Status, Details } = res.data;
        if (Status === 'Success') {
          setVerificationKey(Details);
          setIsLoading(false);
        }
      }
    } catch (error) {
      const errMsg = error.response.data;
      if (errMsg === "user doesn't exist") setError("User doesn't exist");
    }

    setIsLoading(false);
  }

  async function verifyForgetPassword() {
    const config = {
      method: 'post',
      url: '/api/user/verify/otp',
      data: {
        verification_key: verificationKey,
        otp: parseInt(otp),
        check: email,
        newPassword,
        doubleNewPassword
      }
    };

    setError('');
    setIsLoading(true);

    try {
      if (!otp) setError('Please enter your OTP');
      else if (isNaN(parseInt(otp))) setError('Please enter a valid OTP');
      else {
        const res = await axios(config);
        setIsPasswordUpdated(true);
      }
    } catch (error) {
      setError('Error');
    }
  }

  return (
    <Popup
      trigger={
        <button className="button hover:text-accentColor" onClick={() => setVisibility(true)}>
          Login
        </button>
      }
      modal
      open={visibility}
      onClose={() => {
        setVisibility(false);
        setIsForgetPassword(false);
        setIsLoading(false);
        setEmail('');
        setPassword('');
        setVerificationKey('');
        setOtp('');
        setError('');
      }}>
      {(close) => (
        <div className="flex flex-col px-8 pt-4 pb-8">
          {!isForgetPassword ? (
            <>
              <div className="flex flex-row">
                <button className="close" onClick={close}>
                  <p style={{ fontSize: '40px' }}>&times;</p>
                </button>
                <h1 className="self-center text-xl pl-2">Login</h1>
              </div>
              {!verificationKey ? (
                <>
                  {error && <span className="text-accentColor">{error}</span>}
                  <p className="header"> Student email: </p>
                  <input
                    id="email"
                    type="text"
                    className="bg-stone-300 h-10 w-full p-2"
                    placeholder="Enter email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                  />
                  <p className="header2 mt-4"> Student password: </p>
                  <input
                    id="password"
                    type="password"
                    className="bg-stone-300 h-10 w-full p-2"
                    placeholder="Enter password here"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                  />
                  <div className="mt-4 flex flex-row">
                    <div className="flex flex-col">
                      <div className="flex flex-row hover:underline hover:text-accentColor hover:cursor-pointer">
                        <Register />
                        <span className="self-center">?</span>
                      </div>
                      <span
                        className="hover:underline hover:text-accentColor hover:cursor-pointer"
                        onClick={() => setIsForgetPassword(true)}>
                        Forgot password?
                      </span>
                    </div>

                    {isLoading ? (
                      <button className="bg-red-600 text-white py-2 px-4 mr-0 ml-auto">
                        <svg
                          className="animate-spin my-2 mr-3 h-5 w-5 text-white inline-block"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24">
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Loading...
                      </button>
                    ) : (
                      <button
                        className="bg-red-600 text-white py-2 px-4 mr-0 ml-auto"
                        onClick={login}>
                        Login
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h1 className="text-center">Please enter the OTP sent to:</h1>
                  <h1 className="text-center text-accentColor">{email}</h1>
                  <input
                    className="place-self-center mt-4 rounded-none focus:rounded-none p-2 border-2 w-48 text-center text-lg"
                    type="text"
                    placeholder="Enter OTP here"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && verifyLogin()}
                  />
                  <p className="text-center text-accentColor">
                    Please double check your spam email folder
                  </p>
                  {isLoading ? (
                    <button className="place-self-center bg-accentColor text-white py-2 px-4 mt-4">
                      <svg
                        className="animate-spin my-2 mr-3 h-5 w-5 text-white inline-block"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Verifying...
                    </button>
                  ) : (
                    <button
                      className="place-self-center bg-accentColor text-white py-2 px-4 mt-4"
                      onClick={verifyLogin}>
                      Verify Login
                    </button>
                  )}

                  {error && <span className="text-accentColor mt-4 text-center">{error}</span>}
                </>
              )}
            </>
          ) : (
            <>
              <div className="flex flex-row">
                <button className="close" onClick={close}>
                  <p style={{ fontSize: '40px' }}>&times;</p>
                </button>
                <h1 className="self-center text-xl pl-2">Forget Password</h1>
              </div>
              {!verificationKey ? (
                <>
                  {error && <span className="text-accentColor">{error}</span>}
                  <p className="header"> Student email: </p>
                  <input
                    id="email"
                    type="text"
                    className="bg-stone-300 h-10 w-full p-2"
                    placeholder="Enter email here"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && forgetPassword()}
                  />
                  <p className="header2 mt-4">New password: </p>
                  <input
                    type="password"
                    className="bg-stone-300 h-10 w-full p-2"
                    placeholder="Enter new password here"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && forgetPassword()}
                  />
                  <p className="header2 mt-4">Confirm new password: </p>
                  <input
                    type="password"
                    className="bg-stone-300 h-10 w-full p-2"
                    placeholder="Confirm new password here"
                    value={doubleNewPassword}
                    onChange={(e) => setDoubleNewPassword(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && forgetPassword()}
                  />
                  <div className="flex flex-row mt-4">
                    <button
                      className="bg-red-600 text-white py-2 px-4 mr-0 ml-auto"
                      onClick={forgetPassword}>
                      {isLoading ? 'Loading...' : 'Change Password'}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {!isPasswordUpdated ? (
                    <>
                      <h1 className="text-center">Please enter the OTP sent to:</h1>
                      <h1 className="text-center text-accentColor">{email}</h1>
                      <input
                        className="place-self-center mt-4 rounded-none focus:rounded-none p-2 border-2 w-48 text-center text-lg"
                        type="text"
                        placeholder="Enter OTP here"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && verifyForgetPassword()}
                      />
                      <p className="text-center text-accentColor">
                        Please double check your spam email folder
                      </p>

                      <button
                        className="place-self-center bg-accentColor text-white p-2 w-48"
                        onClick={verifyForgetPassword}>
                        {isLoading ? 'Verifying...' : 'Verify'}
                      </button>
                      {error && <span className="text-accentColor mt-4 text-center">{error}</span>}
                    </>
                  ) : (
                    <>
                      <h1 className="text-center my-4">
                        Your Password has been updated! Please try logging in again.
                      </h1>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {/* <div className="p-1 login-part-two">
                    <p className="header mt-1 mb-1"> An OTP has been sent to the email you have entered: </p>
                    <div className="grid grid-flow-col gap-2">
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                      <input type="text" className="bg-stone-300 h-auto w-10 p-2"></input>
                    </div>
                    <br />
                    <button className="bg-red-600 text-white w-24 absolute bottom-0 right-0 h-10 mr-4 mb-4" onClick={nextPage}>Next</button>
                  </div> */}
        </div>
      )}
    </Popup>
  );
}
