import { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export default function CookiePolicy({ open, setOpen }) {

  const handleClose = () => {
    setOpen(false)
  }

  const handleAgree = () => {
    setOpen(false)
    localStorage.setItem("sutd-project-showcase-cookie-policy", true)
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      className='bg-white fixed z-50 p-4 rounded shadow-md max-w-xs'
    >
      <div className='flex flex-col'>
        <div className='flex flex-row'>
          <IconButton
            size="medium"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
          <span className='self-center font-bold text-xl'>Cookie Policy</span>
        </div>
        <div className='flex flex-row p-4 pt-2'>
          <span>This website uses <a href='https://www.cookiesandyou.com/' target={'_blank'} className='text-red-600'>cookies</a> to give you a better experience.</span>
        </div>
        <div className='flex flex-row justify-end'>
          <button
            className='text-white px-4 py-2 bg-red-500 hover:bg-red-700 font-bold rounded text-sm focus:outline-none'
            onClick={handleAgree}>
            Agree
          </button>
          <button className='ml-2 border border-slate-400 rounded px-4 py-2 text-sm hover:bg-slate-200' onClick={handleClose}>Close</button>
        </div>
      </div>
    </Snackbar>
  )
}
