import React, { useRef, useEffect, useState } from 'react';
import { useSpring, animated, to, useTransition, useSpringRef } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { Link, useNavigate } from 'react-router-dom';
import Rating from './Rating';
import parse from 'html-react-parser';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import DeletePostDialog from './Utils/DeletePostDialog';
import ProjectPage from './ProjectPage';
const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20;
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20;

const wheel = (y) => {
  const imgHeight = window.innerWidth * 0.3 - 20;
  return `translateY(${-imgHeight * (y < 0 ? 6 : 1) - (y % (imgHeight * 5))}px`;
};

export default function Card({ data, setPageStatus, setFilteredProjects, setListOfProjects }) {
  const axiosPrivate = useAxiosPrivate();
  const placeHoldderImage = 'http://i.stack.imgur.com/yZlqh.png';
  const [selectedPost, setSelectedPost] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectData, setProjectData] = useState();
  let navigate = useNavigate();

  const EditPost = (id) => {
    navigate(`/upload?project=${id}`);
  };
  const selectPost = (id) => {
    setDeleteDialogOpen(true);
    setSelectedPost(id);
  };

  const deletePost = async () => {
    try {
      await axiosPrivate({
        method: 'delete',
        url: `/api/posts/${selectedPost}`
      });
      console.log('delete');

      setListOfProjects((prevState) => {
        return prevState.filter((project) => project._id !== selectedPost);
      });

      // setFilteredProjects((prevState) => {
      //   return prevState.filter((project) => project._id !== selectedPost);
      // });

      setDeleteDialogOpen(false);
    } catch (error) {
      if (error.response.status === 204) {
        window.location.reload(false);
      }
    }
  };

  // useEffect(() => {
  //   const preventDefault = (e) => e.preventDefault();
  //   document.addEventListener("gesturestart", preventDefault);
  //   document.addEventListener("gesturechange", preventDefault);
  //
  //   setProjectData(data);
  //   return () => {
  //     document.removeEventListener("gesturestart", preventDefault);
  //     document.removeEventListener("gesturechange", preventDefault);
  //   };
  // }, []);
  //
  // const target = useRef(null);
  // const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, api] = useSpring(
  //   () => ({
  //     rotateX: 0,
  //     rotateY: 0,
  //     rotateZ: 0,
  //     scale: 1,
  //     zoom: 0,
  //     x: 0,
  //     y: 0,
  //     config: { mass: 5, tension: 350, friction: 40 },
  //   })
  // );
  //
  // useGesture(
  //   {
  //     onMove: ({ xy: [px, py], dragging }) =>
  //       !dragging &&
  //       api({
  //         rotateX: calcX(py, y.get()),
  //         rotateY: calcY(px, x.get()),
  //         scale: 1.05,
  //       }),
  //     onHover: ({ hovering }) =>
  //       !hovering && api({ rotateX: 0, rotateY: 0, scale: 1 }),
  //   },
  //   { target, eventOptions: { passive: false } }
  // );
  const props = useSpring({
    opacity: 1,
    transform: 'translate(0px, 0px)',
    from: { opacity: 0, transform: 'translate(0px, 20px)' },
    delay: 400
  });
  return (
    <div
      className={`flex items-center h-full justify-center m-5 hover:scale-110 animate-fadeDownPopIn hover:shadow-4xl transition duration-700 ease-in-out`}>
      {/* <animated.div */}
      {/*   ref={target} */}
      {/*   className={ */}
      {/*     "relative rounded-xl shadow-2xl will-change-transform overflow-hidden transition-shadow duration-500 transition-opacity duration-500 hover:shadow-4xl" */}
      {/*   } */}
      {/*   style={{ */}
      {/*     transform: "perspective(10000px)", */}
      {/*     x, */}
      {/*     y, */}
      {/*     scale: to([scale, zoom], (s, z) => s + z), */}
      {/*     rotateX, */}
      {/*     rotateY, */}
      {/*     rotateZ, */}
      {/*   }} */}
      {/* > */}
      {/* <animated.div className={'relative rounded-xl shadow-2xl overflow-hidden '} style={props}> */}
      <animated.div className={'relative rounded-xl shadow-2xl overflow-hidden '}>
        <img
          src={data.image.length > 0 ? data.image[0].url : placeHoldderImage}
          alt="project"
          className="h-80 w-full object-cover cursor-pointer"
          // onClick={() => { setPageStatus({ open: true, data: data }) }}
          onClick={() => {
            navigate(`/projects/${data._id}`);
          }}
        />

        <div className="px-6 py-4 h-auto " style={{ backgroundColor: '#C1BEBE' }}>
          <h2
            className="whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer"
            // onClick={() => { setPageStatus({ open: true, data: data }) }}
            onClick={() => {
              navigate(`/projects/${data._id}`);
            }}>
            <b>{data.title}</b>
          </h2>

          <div
            className="pb-2 text-textBlack h-32 overflow-hidden cursor-pointer"
            onClick={() => {
              navigate(`/projects/${data._id}`);
            }}>
            <div className="relative pb-2 text-textBlack h-32 overflow-hidden">
              <div>{parse(data.desc)}</div>
              <div className="absolute bottom-0 w-full h-8 bg-gradient-to-b from-transparent to-filterHover" />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-1 pb-2">
            {data.tag.map((data, j) => (
              <p
                className="rounded-lg px-2 text-textBlack"
                style={{ backgroundColor: '#E5E5E5' }}
                key={j}>
                {data.name}
              </p>
            ))}
          </div>
          <div className="grid grid-flow-col justify-between mt-4">
            {data.editable && (
              <div className="grid grid-flow-col gap-2">
                <button
                  className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                  onClick={() => {
                    EditPost(data._id);
                  }}>
                  Edit
                </button>
                <button
                  className="bg-accentColor text-white h-8 text-xl px-2 block mx-auto right-0 left-0 rounded"
                  onClick={() => {
                    selectPost(data._id);
                  }}>
                  Delete
                </button>
              </div>
            )}

            {data && (
              <Rating projectID={data._id} likedBool={data.liked} count={data.upvoteCount} />
            )}
          </div>
          <DeletePostDialog
            open={deleteDialogOpen}
            onOk={deletePost}
            handleClose={() => setDeleteDialogOpen(false)}
          />
        </div>
      </animated.div>
    </div>
  );
}
