import RootLogo from "../images/root-logo-big.png"
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className="bg-bgOffWhite">
            <div className="m-auto max-w-7xl">
                <div className=" flex items-center py-4 justify-between">
                    <div className=" flex flex-row items-center">
                        <div>
                            <img src={RootLogo} alt="RootLogo" className="w-12 sm:w-12 md:w-16 h-auto mr-4 my-auto"></img>
                        </div>

                        <div>
                            SUTD Project Showcase
                        </div>


                    </div>

                    {/* <Link to="/about">Contact</Link> */}

                </div>
            </div>
        </div>
    )
}