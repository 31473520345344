import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import '../../css/personalCard.css'

function Section({ title, subSection }) {
    return (
        <div className="flex flex-col mb-8 mx-20 sm:mx-0 ">
            <h2 className="text-2xl border-b-4 border-black font-bold uppercase">{title}</h2>


            <div className="flex flex-col mt-2">
                {subSection.map((item, index) => (

                    <div className="flex justify-between" key={index}>
                        <div className="flex flex-col">
                            <h3 className="text-xl font-semibold capitalize">{item.subTitle}</h3>
                            {item.project && (
                                <Link to={item.project.link}>
                                    <div className='flex w-full h-20 bg-white rounded-md shadow-lg mb-2 hover:scale-105 transition ease-in-out delay-150 duration-700 hover:translate-x-4 bg-opacity-20 backdrop-blur-lg border-2 border-white'>
                                        <img src={item.project.image} alt={item.project.title} className=" h-full object-contain pl-2 py-1 mr-4" />
                                        <div className="flex flex-col h-full">
                                            <h3 className="text-md font-semibold capitalize">
                                                {item.project.title}
                                            </h3>
                                            <p className="block-with-text">
                                                {item.project.description}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            )}
                            <ul className="list-disc pl-4 mt-2">
                                {item.points.map((point, index) => (
                                    <li key={index}>{point}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="flex flex-col font-semibold min-w-[350px] items-end ">
                            <p className="text-lg">{item.location}</p>
                            {item.endMonth === 'Present' && item.endYear === '0' ? (
                                <p className="text-lg">
                                    {item.startMonth} {item.startYear} to {item.endMonth}
                                </p>
                            ) : (
                                <p className="text-lg">
                                    {item.startMonth} {item.startYear} to {item.endMonth} {item.endYear}
                                </p>
                            )}
                        </div>
                    </div>
                ))
                }
            </div>
        </div>

    );
}

Section.propTypes = {
    title: PropTypes.string.isRequired,

    subSection: PropTypes.arrayOf(PropTypes.shape({
        subTitle: PropTypes.string,
        project: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.string,
        }),
        points: PropTypes.arrayOf(PropTypes.string),
        location: PropTypes.string,
        startMonth: PropTypes.oneOf([
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ]),
        startYear: PropTypes.number,
        endMonth: PropTypes.oneOf([
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
            'Present'
        ]),
        endYear: PropTypes.number
    })).isRequired,


};

export default Section;
