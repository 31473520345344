import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from '../axiosConfig'
import jwt from 'jwt-decode'
const AppContext = createContext({})

export function AppWrapper({ children }) {
  const [state, setState] = useState(null)
  const [loading, setLoading] = useState(true)
  const [token,setToken] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      var user
      var accessToken
      if (!token){
          const response = await axios.get('api/user/jwt/refresh',{withCredentials:true}) 
          accessToken = response.data.Token
          user = jwt(accessToken)
          setToken(accessToken)
      }else{
        user = jwt(token)
        accessToken = token

      }
      const result = await axios({
        method: 'get',
        url: `/api/user/${user._id}`,
        headers:{'auth-token':accessToken}
      })
      setState(result.data)
      setLoading(false)
    }
    fetchData()
  },[token])
  return (
    <AppContext.Provider value={{
      state,
      loading,
      setState,
      token,
      setToken
    }}>
      {children}
    </AppContext.Provider>
  )
}
export default AppContext
