import PersonalCard from '../components/PersonalCard';
import { useRef, useEffect } from 'react';
import TeamPhoto from '../images/poster.jpg';
const IntroSection = ({ title, children }) => {
  const listInnerRef = useRef();
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  const handleScroll = () => {
    const listInner = listInnerRef.current;
    console.log(listInner.scrollHeight);
    console.log(window.pageYOffset, listInner.offsetTop, listInner.offsetHeight);
    const listInnerHeight = listInner.offsetHeight;
    const listInnerTop = listInner.offsetTop + window.pageYOffset;
    const listInnerBottom = listInner.offsetTop + listInnerHeight;
    // const isInView = listInner.offsetTop <= window.pageYOffset + window.innerHeight && listInnerBottom >= window.innerHeight;
    const isInView =
      listInner.offsetTop >= window.innerHeight &&
      listInner.offsetTop <= window.pageYOffset + window.innerHeight;
    if (isInView) {
      console.log('inView');
      listInner.classList.add('animate-slideFadeInUp');
      //set animation time of slideInLeft
      // listInner.style.animationDuration = '1s';
    }
  };
  return (
    <div className=" py-4" ref={listInnerRef}>
      <h1 className="text-textBlack text-4xl font-bold text-center mb-8">{title}</h1>
      <div className="flex justify-evenly flex-wrap items-center">{children}</div>
    </div>
  );
};
export default function About() {
  return (
    <div className="bg-bgOffWhite ">
      <div className=" min-h-screen max-w-6xl m-auto ">
        <div>
          <img src={TeamPhoto}></img>
        </div>
        <IntroSection title={'UI/UX'}>
          {' '}
          <PersonalCard
            github="https://github.com/claudiachin"
            linkedin={'https://www.linkedin.com/in/claudia-chinsh/'}
            role={'UI/UX Designer'}
            image={
              'https://sutdfolio.sgp1.digitaloceanspaces.com/team-photo/photo_2022-07-29_00-02-48.jpg'
            }
            name="Claudia Chin"
            email={'claudia_chin@mymail.sutd.edu.sg'}
            desc={'Just your regular CSD student. It was fun working on this!'}
          />
          <PersonalCard
            // github="https://github.com/claudiachin"
            linkedin={'https://www.linkedin.com/in/cindy-yu-yh'}
            role={'UI/UX Designer'}
            image={'https://sutdfolio.sgp1.digitaloceanspaces.com/team-photo/IMG_3924.jpg'}
            name="Cindy"
            // email={'claudia_chin@mymail.sutd.edu.sg'}
            desc={'EPD student doing UI/UX, hope you’ll find the projects interesting!'}
          />
        </IntroSection>
        <IntroSection title={'Frontend Engineer'}>
          {' '}
          <PersonalCard
            github="https://github.com/chris-lye"
            linkedin={'https://www.linkedin.com/in/christopher-sk-lye/'}
            role={'Frontend Engineer'}
            image={'https://sutdfolio.sgp1.digitaloceanspaces.com/team-photo/20210306_185406.jpg'}
            name="Christopher"
            desc={
              'I enjoy coding and running. Find me on LinkedIn if you want to collaborate on an idea :)'
            }
          />
          <PersonalCard
            github="https://github.com/spideysri2802"
            linkedin={'https://linkedin.com/in/sriramsub2802'}
            role={'Frontend Engineer'}
            image={
              'https://sutdfolio.sgp1.digitaloceanspaces.com/team-photo/photo_2022-07-29_00-03-03.jpg'
            }
            name="Sriram Subramaniam"
            // desc={
            //   'I enjoy coding and running. Find me on LinkedIn if you want to collaborate on an idea :)'
            // }
          />
          <PersonalCard
            github="https://github.com/thatrichan"
            linkedin={'https://www.linkedin.com/in/kevin-richan-1357b3147/'}
            role={'Frontend Engineer'}
            image={
              'https://sutdfolio.sgp1.digitaloceanspaces.com/team-photo/photo_2022-07-29_00-02-52.jpg'
            }
            name="Kevin Richan"
            // desc={
            //   'I enjoy coding and running. Find me on LinkedIn if you want to collaborate on an idea :)'
            // }
          />
        </IntroSection>
        <IntroSection title={'Backend Engineer'}>
          <PersonalCard
            github="https://github.com/weihong0827"
            linkedin={'www.linkedin.com/in/weihong0225'}
            image={
              'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg'
            }
            name="Qiu Weihong"
            role="Backend Engineer"
            email={'weihong_qiu@mymail.stud.edu.sg'}
            desc={
              'I really enjoy the process of working on this project. I have picked up many new skills and concepts to make this live!'
            }
          />
        </IntroSection>
        {/* <div className="bg-red-100 py-10"> */}
        {/*   <h1 className="text-textBlack text-4xl font-bold text-center mb-8">Project Director</h1> */}
        {/*   <div className="flex justify-evenly flex-wrap items-center"> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="bg-orange-100 py-10"> */}
        {/*   <h1 className="text-textBlack text-4xl font-bold text-center mb-8">UI/UX</h1> */}
        {/*   <div className="flex justify-evenly flex-wrap items-center"> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="bg-green-100 py-10"> */}
        {/*   <h1 className="text-textBlack text-4xl font-bold text-center mb-8">Frontend Engineer</h1> */}
        {/*   <div className="flex justify-evenly flex-wrap items-center"> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="bg-teal-100 py-10"> */}
        {/*   <h1 className="text-textBlack text-4xl font-bold text-center mb-8">Backend Engineer</h1> */}
        {/*   <div className="flex justify-evenly flex-wrap items-center"> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="bg-cyan-100 py-10"> */}
        {/*   <h1 className="text-textBlack text-4xl font-bold text-center mb-8">Special Thanks</h1> */}
        {/*   <div className="flex justify-evenly flex-wrap items-center"> */}
        {/*     <PersonalCard */}
        {/*       github="https://github.com/weihong0827" */}
        {/*       linkedin={'www.linkedin.com/in/weihong0225'} */}
        {/*       image={ */}
        {/*         'https://sutdfolio.sgp1.digitaloceanspaces.com/avatar/7b17de38photo_2022-04-01_13-35-46.jpg' */}
        {/*       } */}
        {/*       name="Qiu Weihong" */}
        {/*       email={'weihong_qiu@mymail.stud.edu.sg'} */}
        {/*       desc={ */}
        {/*         'Minim quis do aliquip anim laborum velit cupidatat reprehenderit cillum. Irure reprehenderit duis duis exercitation dolor commodo aliqua ad et quis.' */}
        {/*       } */}
        {/*     /> */}
        {/*   </div> */}
        {/* </div> */}
      </div>
    </div>
  );
}
