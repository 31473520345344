import { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import axios from 'axios';
import hljs from 'highlight.js';
import '../css/a11y-dark.css';
import '../css/editor.css';
// import ImageCompress from 'quill-image-compress';

// Quill.register('modules/imageCompress', ImageCompress);
Quill.register('modules/imageUploader', ImageUploader);
// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida'];
Quill.register(Font, true);

export const modules = {
  syntax: {
    highlight: (text) => hljs.highlightAuto(text).value
  },

  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ['link', 'image', 'formula'],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ['clean'] // remove formatting button
  ],
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        axios
          .post('/api/file?dest=contentImage&compress=true', formData)

          .then((result) => {
            console.log(result);
            resolve(result.data[0].compressUrl);
          })
          .catch((error) => {
            reject('Upload failed');
            console.error('Error:', error);
          });
      });
    }
  },

  // imageCompress: {
  //     quality: 0.7, // default
  //     maxWidth: 200, // default
  //     maxHeight: 200, // default

  //     debug: true, // default
  //     suppressErrorLogging: false, // default
  // },

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'script',
  'code-block',
  'formula',
  'link',
  'image',
  'video',
  'background',
  'align',
  'color'
];
