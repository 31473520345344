import { useEffect } from 'react'
import useAppContext from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'

export const useUser = ({ redirecTo }) => {
  const { state, loading, setState } = useAppContext()
  let navigate = useNavigate()

  useEffect(() => {
    console.log(state)
    if (!loading) {
      if (!state) {
        navigate(redirecTo, { replace: true })
      }
    }
  }, [loading, state])

  return { state, setState }
}
