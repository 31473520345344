import React from 'react'
import Section from './Section'
export default function Portfolio() {
    const subSection = [{
        subTitle: 'Singapore University of Technology and Design (SUTD)',
        points: [
            'Bachelor of Engineering (Computer Science and Design), Honors',
            'GPA 4.2/5.0, SUTD Merit Scholarship Holder',
            'Expected Date of Graduation: April 2024'
        ],
        location: 'Singapore',
        startMonth: 'January',
        startYear: '2019',
        endMonth: 'December',
        endYear: '2022'

    }]
    const project = [{
        subTitle: 'Gamified Learning Platform, Backend Developer',
        project: {
            title: 'Quizora',
            link: '/projects/626600370be84a8ddebde34e',
            description: 'Consectetur elit aliqua consequat sunt nulla sunt sit eiusmod sunt occaecat veniam est tempor mollit. Fugiat veniam amet aute commodo tempor non esse. Dolore duis elit adipisicing dolor eiusmod cupidatat deserunt non ex eu aliquip.',
            image: 'https://sutdfolio.sgp1.cdn.digitaloceanspaces.com/projectImage/9f5016e1C101_Team1C_Poster.png',
        },
        points: [
            'Build a REST api using Django with relational database and design the question distribution logic to help the professors with the analysis of student performance ',
            'Optimized the performance of the api using redis and have seen significant improvement from an average of 200ms per request to an average of 5ms per request',
            'Collaborate with the frontend team in the integration of the api into the webapp build using Vue.js '
        ],
        location: 'Singapore',
        startMonth: 'May',
        startYear: '2021',
        endMonth: 'Present',
        endYear: '0'
    }]
    return (
        <div className='max-w-7xl m-auto  flex flex-col'>
            <h1 className="text-4xl text-center mb-4">
                Qiu Weihong
            </h1>
            <div className="flex flex-col items-center mb-8">
                <div className='flex'>
                    <p className='pr-4'>Email:weihong_qiu@mymail.sutd.edu.sg</p>
                    <p>Phone:+65 98888888</p>
                </div>
                <div className='flex'>

                    <p className='pr-4'>Linkedin:<a href='//www.linkedin.com/in/weihong-qiu'>linkedin.com/in/weihong-qiu</a></p>
                    <p>Github:<a href='//www.github.com/weihong0827'>github.com/in/weihong0827</a></p>
                </div>
            </div>
            <Section title='Education' subSection={subSection} />
            <Section title='Academic Projects' subSection={project} />

        </div>
    )
}
