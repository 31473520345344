import React, { useState, useEffect } from "react";
// import axios from "axios";
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAppContext from '../hooks/useAuth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as DarkThumb } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as LightThumb } from '@fortawesome/free-regular-svg-icons'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// import Alert from "./Utils/Alert";

export default function Rating(props) {
    const axiosPrivate = useAxiosPrivate()
    const state = useAppContext();
    const [open, setOpen] = useState(false)
    const [count, setCount] = useState(props.count)
    const [like, setLike] = useState(props.likedBool);

    const getUpvoteData = async () => {
        //this actually sets the backend to liked/not liked.
        console.log("projectID: " + props.projectID);
        await axiosPrivate
            .get("api/posts/upvote/" + props.projectID, {})

    }
    useEffect(() => {
        setLike(props.likedBool)
    }, [props.likedBool]);

    function toggle() {
        if (state.state === null) {
            setOpen(true)
        } else {
            if (props.count !== undefined) {
                if (like) {
                    setCount(count - 1)
                } else {
                    setCount(count + 1)
                }

            }
            setLike(!like);
            getUpvoteData();
        }

    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <div className="flex ">
            {/* <h1>Rating Module</h1> */}
            <div className="hidden" > <FontAwesomeIcon icon={DarkThumb} /> </div>
            <div className="hidden"> <FontAwesomeIcon icon={LightThumb} /> </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Please Login To Like
                </Alert>
            </Snackbar>
            <button onClick={toggle}>
                {!like ? (<FontAwesomeIcon icon={LightThumb} size="2x" />) : (<FontAwesomeIcon icon={DarkThumb} size="2x" />)}
            </button>
            {props.count !== undefined && <p className="p-0 mt-auto mb-auto leading-8 ml-4">{count}</p>}
        </div>

    )
}
