import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import axios from '../axiosConfig';
import Zoom from '@mui/material/Zoom';
import validateEmail from '../utils/validateEmail';
import { Checkbox, Tooltip } from '@mui/material';

export default function Register() {
  const [visibility, setVisibility] = useState(false);
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [studentID, setStudentID] = useState('');
  const [checked, setChecked] = useState(false);
  const [verificationKey, setVerificationKey] = useState('');
  const [otp, setOtp] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isUserActivated, setIsUserActivated] = useState(false);

  const handleCheckChange = (e) => {
    setChecked(e.target.checked);
  };
  const PDPA =
    'By signing up, I agree that ROOT may collect, use and disclose the information above, within the planning committee, for planning purposes. My personal data will not be retained and will be disposed appropriately if I submit a request to remove my projects.';
  async function register() {
    const config = {
      method: 'post',
      url: '/api/user/register',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        name: name,
        email: email,
        password: password,
        studentId: studentID
      }
    };

    setError('');
    setIsLoading(true);

    try {
      if (!name) setError('Please enter your name');
      else if (!email) setError('Please enter your email');
      else if (!validateEmail(email)) setError('Please enter a valid email');
      else if (!password) setError('Please enter a password');
      else if (password !== confirmPassword) setError('Passwords do not match');
      else if (!studentID) setError('Please enter your student ID');
      else if (
        isNaN(parseInt(studentID)) ||
        parseInt(studentID) < 1000000 ||
        parseInt(studentID) > 1100000
      )
        setError('Please enter a valid student ID');
      else {
        await axios(config);

        const res = await axios({
          method: 'post',
          url: '/api/user/email/otp',
          data: { email, type: 'VERIFICATION' }
        });

        const { Status, Details } = res.data;

        if (Status === 'Success') {
          setVerificationKey(Details);
        }
      }
    } catch (error) {
      console.log(error);
      const errMsg = error.response.data;

      if (errMsg === 'email already exist') setError('User already exists');
      else if (errMsg === 'email is not sutd student email')
        setError('Please enter your SUTD student email');
      else if (errMsg === 'studentId already exist') setError('Student ID already exists');
      else setError(errMsg);
    }

    setIsLoading(false);
  }

  async function verifyEmail() {
    const config = {
      method: 'post',
      url: '/api/user/verify/otp',
      data: {
        verification_key: verificationKey,
        otp: parseInt(otp),
        check: email
      }
    };

    setError('');
    setIsLoading(true);

    try {
      if (!otp) setError('Please enter your OTP');
      else if (isNaN(parseInt(otp))) setError('Please enter a valid OTP');
      else {
        await axios(config); // verify email
        setIsUserActivated(true);
      }
    } catch (error) {
      const { Status, Details } = error.response.data;

      if (Status === 'Failure') {
        if (Details === 'OTP NOT Matched') setError('OTP does not match');
        else if (Details === 'OTP Expired') setError('OTP has expired');
        else if (Details === 'OTP Already Used') setError('OTP has already been used');
        else {
          setError('Bad Request!');
        }
      }
    }

    setIsLoading(false);
  }

  return (
    <Popup
      trigger={<button className="button1 hover:text-accentColor">Register</button>}
      modal
      open={visibility}
      onClose={() => {
        setVisibility(false);
        setIsLoading(false);
      }}>
      {(close) => (
        <div className="px-8 pt-4 pb-8">
          <div className="flex flex-row">
            <button className="close" onClick={close}>
              <p style={{ fontSize: '40px' }}>&times;</p>
            </button>
            <h1 className="self-center text-xl pl-2">Registration</h1>
          </div>
          {!verificationKey ? (
            <div className="flex flex-col">
              {error && <span className="text-accentColor">{error}</span>}
              <p>Student name: </p>
              <input
                id="name"
                type="text"
                className="bg-stone-300 h-10 w-full p-2"
                placeholder="Enter name here"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className="mt-4"> Student email: </p>
              <input
                id="email"
                type="text"
                className="bg-stone-300 h-10 w-full p-2"
                placeholder="Enter email here"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="mt-4"> Student password: </p>
              <input
                id="password"
                type="password"
                className="bg-stone-300 h-10 w-full p-2"
                placeholder="Enter password here"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="mt-4">Confirm Student password: </p>
              <input
                type="password"
                className="bg-stone-300 h-10 w-full p-2"
                placeholder="Confirm password here"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {password && confirmPassword !== password && (
                <span className="text-accentColor text-md">Passwords do not match</span>
              )}
              <p className="mt-4"> Student ID: </p>
              <input
                id="student-id"
                type="text"
                className="bg-stone-300 h-10 w-full p-2"
                placeholder="Enter student ID here"
                value={studentID}
                onChange={(e) => setStudentID(e.target.value)}
              />
              <br />
              <div className="flex flex-row w-full justify-center items-center">
                <Checkbox onChange={handleCheckChange} />
                <Tooltip title={PDPA} placement={'top'} arrow TransitionComponent={Zoom}>
                  <p className="text-red-600">PDPA Clause</p>
                </Tooltip>
              </div>
              <div className="flex flex-row">
                {/* <Login/>
                  <span className="self-center text-lg">?</span> */}

                {/* <button className={`${checked ? 'bg-red-600' : 'bg-gray-600'} text-white py-2 px-4 mr-0 ml-auto `} onClick={register} disabled={!checked}>
                  {isLoading ? 'Loading...' : 'Register'}
                 </button> */}
                {isLoading ? (
                  <button className="bg-red-600 text-white py-2 px-4 mr-0 ml-auto">
                    <svg
                      className="animate-spin my-2 mr-3 h-5 w-5 text-white inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Loading...
                  </button>
                ) : (
                  <button
                    className={`${
                      checked ? 'bg-red-600' : 'bg-gray-600'
                    } text-white py-2 px-4 mr-0 ml-auto`}
                    disabled={!checked}
                    onClick={register}>
                    Register
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              {!isUserActivated ? (
                <>
                  <h1 className="text-center">Please enter the OTP sent to:</h1>
                  <h1 className="text-center text-accentColor">{email}</h1>
                  <input
                    className="place-self-center mt-4 rounded-none focus:rounded-none p-2 border-2 w-48 text-center text-lg"
                    type="text"
                    placeholder="Enter OTP here"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && verifyEmail()}
                  />{' '}
                  <p className="text-center text-accentColor">
                    Please double check your spam email folder
                  </p>
                  {isLoading ? (
                    <button className="bg-red-600 text-white py-2 px-4 mr-0 ml-auto ">
                      <svg
                        className="animate-spin my-2 mr-3 h-5 w-5 text-white inline-block"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Verifying...
                    </button>
                  ) : (
                    <button
                      className="place-self-center bg-accentColor text-white py-2 px-4 mt-4 "
                      onClick={verifyEmail}>
                      Verify Email
                    </button>
                  )}
                  {/* <button className="place-self-center bg-accentColor text-white p-2 w-48" onClick={verifyEmail}>
                      {isLoading ? 'Verifying...' : 'Verify Email'}
                    </button> */}
                  {error && <span className="text-accentColor mt-4 text-center">{error}</span>}
                </>
              ) : (
                <>
                  <h1 className="text-center">
                    Thank you for registering! Please login to access your account :)
                  </h1>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Popup>
  );
}
