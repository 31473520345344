import { axiosPrivate } from "../axiosConfig";
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken'
import useAppContext from '../hooks/useAuth'
const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { state, token } = useAppContext();
  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      config => {
        if (!config.headers.common['auth-token']) {
          config.headers.common['auth-token'] = token
        }
        return config;
      }, (error) => Promise.reject(error)
    )
    const responseIntercept = axiosPrivate.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.reponse?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers.common['auth-token'] = newAccessToken;
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [token, refresh])
  return axiosPrivate
}
export default useAxiosPrivate;
